import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RoutingModule} from '../app-routing.module';
import {SidenavComponent} from './sidenav/sidenav.component';
import {SidenavItemComponent} from './sidenav/sidenav-item/sidenav-item.component';
import {AdminComponent} from './admin/admin.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {SidenavService} from './sidenav/sidenav.service';
import {MediaReplayService} from './sidenav/mediareplay/media-replay.service';
import {MaterialComponentsModule} from '../material-components.module';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {LoginComponent} from './login/login/login.component';
import {ForgotPasswordComponent} from './login/forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './security/change-password/change-password.component';

import {LoadingOverlayService} from '../_services/loading-overlay.service';

import {AuthGuard} from '../_services/authguard.service';

import {CustomPipesModule} from '../_pipes/custom-pipes.module';

import {NgBoxModule} from './ngbox/ngbox.module';
import {NgBoxService} from './ngbox/ngbox.service';
import {AuthenticationService} from '../_services/authentication.service';
import {ApiService} from '../_interceptors/api.service';
import {CustomErrorHandlerService} from '../_interceptors/http.error-handler.service';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {PageForbiddenComponent} from './page-forbidden/page-forbidden.component';
import {SnackbarService} from '../_services/snackbar.service';
import {PageTitleService} from '../_services/page-title.service';
import {LocalStorageService} from '../_services/local-storage.service';
import {FormsService} from '../_services/forms.service';
import {SimpleModalDialogComponent} from './simple-modal/simple-modal.component';
import {ToolbarBottomComponent} from './toolbar-bottom/toolbar-bottom.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {IosInstallPromptComponent} from './ios-install-prompt/ios-install-prompt.component';
import {RecoverLostPasswordComponent} from './login/recover-lost-password/recover-lost-password.component';
import {LoginWithRecoveryCodeComponent} from './login/login-with-recovery-code/login-with-recovery-code.component';
import {LoginTwoFactorAuthenticationComponent} from './login/login-two-factor-authentication/login-two-factor-authentication.component';
import {MyAccountComponent} from './security/my-account/my-account.component';
import {MyAccountRouterComponent} from './security/my-account-router/my-account-router.component';
import {RemovablePhotoComponent} from './removable-photo/removable-photo.component';
import {BuildingMapWidgetComponent} from './building-map-widget/building-map-widget.component';

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule,
		MaterialComponentsModule,
		FormsModule,
		ReactiveFormsModule,
		RoutingModule,
		FlexLayoutModule,
		CustomPipesModule.forRoot(),
		NgBoxModule,
		InfiniteScrollModule,
		MaterialFileInputModule
	],
	entryComponents: [
		SimpleModalDialogComponent,
		IosInstallPromptComponent,
		RemovablePhotoComponent,
		BuildingMapWidgetComponent,
	],
	declarations: [
		SidenavComponent,
		SidenavItemComponent,
		AdminComponent,
		ToolbarComponent,
		ToolbarBottomComponent,
		LoginComponent,
		ForgotPasswordComponent,
		RecoverLostPasswordComponent,
		LoginTwoFactorAuthenticationComponent,
		LoginWithRecoveryCodeComponent,
		MyAccountComponent,
		MyAccountRouterComponent,
		ChangePasswordComponent,
		SimpleModalDialogComponent,
		PageNotFoundComponent,
		PageForbiddenComponent,
		IosInstallPromptComponent,
		RemovablePhotoComponent,
		BuildingMapWidgetComponent,
	],
	providers: [
		PageTitleService,
		SidenavService,
		SnackbarService,
		MediaReplayService,
		AuthGuard,
		AuthenticationService,
		LoadingOverlayService,
		NgBoxService,
		ApiService,
		CustomErrorHandlerService,
		FormsService,
		LocalStorageService
	],
	exports: [
		SimpleModalDialogComponent
	]
})
export class CoreModule {
}
