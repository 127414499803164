import {Injectable} from '@angular/core';
import {NgxIndexedDB} from 'ngx-indexed-db/dist/ngx-indexed-db';
import {ApiService} from '../_interceptors/api.service';
import {ServerResponse} from '../_interfaces/server.response';
import {BehaviorSubject, Subscription} from 'rxjs/Rx';
import {FileInput} from 'ngx-material-file-input';
import {ReportsShortcomingsCategories} from '../_interfaces/reports/reports-shortcomings-categories';
import {ReportsShortcomingsSubcategories} from '../_interfaces/reports/reports-shortcomings-subcategories';
import {ReportsShortcomingsTypes} from '../_interfaces/reports/reports-shortcomings-types';
import {ReportsShortcomingsActions} from '../_interfaces/reports/reports-shortcomings-actions';
import {Reports} from '../_interfaces/reports/reports';
import {ReportsAttachments} from '../_interfaces/reports/reports-attachments';
import {ReportsComments} from '../_interfaces/reports/reports-comments';
import {ReportsBuildings} from '../_interfaces/reports/reports-buildings';
import {ReportsBuildingsReports} from '../_interfaces/reports/reports-buildings-reports';
import {Router} from '@angular/router';
import {PreviousUrlService} from './previous-url.service';
import {SnackbarService} from './snackbar.service';
import {AuthenticationService} from './authentication.service';

import {LoadingOverlayService} from './loading-overlay.service';
import {environment} from '../../environments/environment';
import * as moment from 'moment';
import {dbVersion} from '../../environments/version';

@Injectable()

export class IndexedDBService {

	private syncStart: number = 0;

	public database = null;
	public databaseReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public syncComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public tableDataSync: string = 'data_sync';
	public tableKvvmReports: string = 'kvvm_reports';
	public tableKvvmReportsAttachments: string = 'kvvm_reports_attachments';
	public tableKvvmReportsComments: string = 'kvvm_reports_comments';
	public tableKvvmReportsBuildings: string = 'kvvm_reports_buildings';
	public tableKvvmReportsBuildingsReports: string = 'kvvm_reports_buildings_reports';
	public tableKvvmReportsBuildingsReportsSolved: string = 'kvvm_reports_buildings_reports_solved';

	public tableReportsCategories: string = 'reports_categories';
	public tableReportsSubcategories: string = 'reports_subcategories';
	public tableReportsActions: string = 'reports_actions';
	public tableReportsTypes: string = 'reports_types';
	public tableReports: string = 'reports';
	public tableReportsPush: string = 'reports_push';
	public tableReportsAttachments: string = 'reports_attachments';
	public tableReportsAttachmentsPush: string = 'reports_attachments_push';
	public tableReportsComments: string = 'reports_comments';
	public tableReportsCommentsPush: string = 'reports_comments_push';
	public tableReportsBuildings: string = 'reports_buildings';
	public tableReportsBuildingsPush: string = 'reports_buildings_push';
	public tableReportsBuildingsReports: string = 'reports_buildings_reports';
	public tableReportsBuildingsReportsPush: string = 'reports_buildings_reports_push';
	public tableReportsBuildingsReportsPhotos: string = 'reports_buildings_reports_photos';
	public tableReportsSendToClient: string = 'reports_send_to_client';

	public tableSettings: string = 'settings';

	private dbVersion: number = dbVersion;
	private dbName: string = 'KVVM-Klant';
	private debug: boolean = !environment.production;
	private tryToCreateDatabase: number = 10;
	private databaseCreationTries: number = 0;

	private _authenticationService: Subscription = null;

	constructor(private apiService: ApiService,
				private loadingOverlayService: LoadingOverlayService,
				private authenticationService: AuthenticationService,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private router: Router) {
		this.openIndexedDatabase();
	}

	private openIndexedDatabase(): void {
		this.database = new NgxIndexedDB(this.dbName, this.dbVersion);
		this.database.openDatabase(this.dbVersion, (evt: IDBVersionChangeEvent) => this.updateDatabase(evt))
			.then(() => {
				if (this.validateDatabase()) {
					this.databaseReady.next(true);

					this.syncStart = new Date().getTime();
					this.removeOldData();
					this.syncAll();

					this.registerToEvents(this.apiService);

					setInterval(() => {
						if (this.apiService.isOnline) {
							this.syncAll();
						}
					}, (60 * 60) * 1000);
				}
			}, error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
	}

	private updateDatabase(evt: IDBVersionChangeEvent): boolean {
		let db: IDBDatabase = (<IDBOpenDBRequest>evt.target).result;
		if (db.objectStoreNames.contains(this.tableDataSync) === false) {
			this.createDataSyncTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableKvvmReports) === false) {
			this.createKvvmReportsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableKvvmReportsAttachments) === false) {
			this.createKvvmReportsAttachmentsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableKvvmReportsComments) === false) {
			this.createKvvmReportsCommentsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableKvvmReportsBuildings) === false) {
			this.createKvvmReportsBuildingsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableKvvmReportsBuildingsReports) === false) {
			this.createKvvmReportsBuildingsReportsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableKvvmReportsBuildingsReportsSolved) === false) {
			this.createKvvmReportsBuildingsReportsSolvedTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsCategories) === false) {
			this.createReportsCategoriesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsSubcategories) === false) {
			this.createReportsSubcategoriesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsActions) === false) {
			this.createReportsActionsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsTypes) === false) {
			this.createReportsTypesTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReports) === false) {
			this.createReportsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsPush) === false) {
			this.createReportsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsAttachments) === false) {
			this.createReportsAttachmentsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsAttachmentsPush) === false) {
			this.createReportsAttachmentsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsComments) === false) {
			this.createReportsCommentsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsCommentsPush) === false) {
			this.createReportsCommentsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildings) === false) {
			this.createReportsBuildingsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildingsPush) === false) {
			this.createReportsBuildingsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildingsReports) === false) {
			this.createReportsBuildingsReportsTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildingsReportsPush) === false) {
			this.createReportsBuildingsReportsPushTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsBuildingsReportsPhotos) === false) {
			this.createReportsBuildingsReportsPhotosTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableReportsSendToClient) === false) {
			this.createReportsSendToClientTable(evt);
		}
		if (db.objectStoreNames.contains(this.tableSettings) === false) {
			this.createSettingsTable(evt);
		}

		return true;
	}

	private validateDatabase(): boolean {
		let db = this.database.dbWrapper.db;

		if (this.databaseCreationTries > this.tryToCreateDatabase) {
			this.loadingOverlayService.show();
			this.snackbarService.error('A serious error occurred with the database. Please reload the app.');
			this.database.deleteDatabase(this.dbName);
			return false;
		}

		this.databaseCreationTries++;

		if (db.objectStoreNames.contains(this.tableDataSync) === false ||
			db.objectStoreNames.contains(this.tableKvvmReports) === false ||
			db.objectStoreNames.contains(this.tableKvvmReportsAttachments) === false ||
			db.objectStoreNames.contains(this.tableKvvmReportsComments) === false ||
			db.objectStoreNames.contains(this.tableKvvmReportsBuildings) === false ||
			db.objectStoreNames.contains(this.tableKvvmReportsBuildingsReports) === false ||
			db.objectStoreNames.contains(this.tableKvvmReportsBuildingsReportsSolved) === false ||
			db.objectStoreNames.contains(this.tableReportsCategories) === false ||
			db.objectStoreNames.contains(this.tableReportsSubcategories) === false ||
			db.objectStoreNames.contains(this.tableReportsActions) === false ||
			db.objectStoreNames.contains(this.tableReportsTypes) === false ||
			db.objectStoreNames.contains(this.tableReports) === false ||
			db.objectStoreNames.contains(this.tableReportsPush) === false ||
			db.objectStoreNames.contains(this.tableReportsAttachments) === false ||
			db.objectStoreNames.contains(this.tableReportsAttachmentsPush) === false ||
			db.objectStoreNames.contains(this.tableReportsComments) === false ||
			db.objectStoreNames.contains(this.tableReportsCommentsPush) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildings) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildingsPush) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildingsReports) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildingsReportsPush) === false ||
			db.objectStoreNames.contains(this.tableReportsBuildingsReportsPhotos) === false ||
			db.objectStoreNames.contains(this.tableReportsSendToClient) === false ||
			db.objectStoreNames.contains(this.tableSettings) === false) {
			console.log('There\'s an issue with the database, we\'ll try to repair it ...');
			this.database.deleteDatabase(this.dbName);
			this.openIndexedDatabase();
			return false;
		}

		return true;
	}

	private registerToEvents(apiService: ApiService) {
		apiService.connectionChanged.subscribe(online => {
			if (online) {
				this.databaseReady.subscribe(event => {
					if (event) {
						if (this.debug) {
							console.log('user went online, push any and all changes to the server');
						}
						this.apiService.offlineError.emit(false);

						this.syncStart = new Date().getTime();
						this.startUpdating();
					} else {
						if (this.debug) {
							console.log('user went online but database is not yet ready');
						}
					}
				});
			}
		});
	}

	createDataSyncTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableDataSync, {
			keyPath: 'name',
			autoIncrement: false
		});
		object.createIndex('name', 'name', {unique: true});
	}

	createKvvmReportsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableKvvmReports, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
	}

	createKvvmReportsAttachmentsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableKvvmReportsAttachments, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
	}

	createKvvmReportsCommentsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableKvvmReportsComments, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
	}

	createKvvmReportsBuildingsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableKvvmReportsBuildings, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
	}

	createKvvmReportsBuildingsReportsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableKvvmReportsBuildingsReports, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
	}

	createKvvmReportsBuildingsReportsSolvedTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableKvvmReportsBuildingsReportsSolved, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
	}

	createReportsCategoriesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsCategories, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
	}

	createReportsSubcategoriesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsSubcategories, {
			keyPath: ['id', 'category_id'],
			autoIncrement: false
		});
		object.createIndex('entryId', ['id', 'category_id'], {unique: true});
	}

	createReportsActionsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsActions, {
			keyPath: ['id', 'category_id', 'subcategory_id'],
			autoIncrement: false
		});
		object.createIndex('entryId', ['id', 'category_id', 'subcategory_id'], {unique: true});
	}

	createReportsTypesTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsTypes, {
			keyPath: ['id', 'category_id', 'subcategory_id'],
			autoIncrement: false
		});
		object.createIndex('entryId', ['id', 'category_id', 'subcategory_id'], {unique: true});
	}

	createReportsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReports, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
	}

	createReportsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
	}

	createReportsAttachmentsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsAttachments, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
	}

	createReportsAttachmentsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsAttachmentsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
	}

	createReportsCommentsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsComments, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
	}

	createReportsCommentsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsCommentsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
	}

	createReportsBuildingsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildings, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
	}

	createReportsBuildingsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
	}

	createReportsBuildingsReportsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReports, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
	}

	createReportsBuildingsReportsPushTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReportsPush, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
		object.createIndex('indexedDBMethod', 'indexedDBMethod', {unique: false});
	}

	createReportsBuildingsReportsPhotosTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReportsPhotos, {
			keyPath: 'id',
			autoIncrement: true
		});
		object.createIndex('id', 'id', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
		object.createIndex('building_report_id', 'building_report_id', {unique: false});
	}

	createReportsSendToClientTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableReportsSendToClient, {
			keyPath: 'entryId',
			autoIncrement: true
		});
		object.createIndex('entryId', 'entryId', {unique: true});
		object.createIndex('report_id', 'report_id', {unique: false});
	}

	createSettingsTable(evt): void {
		let object = evt.currentTarget.result.createObjectStore(this.tableSettings, {
			keyPath: 'name',
			autoIncrement: false
		});
		object.createIndex('name', 'name', {unique: true});
		object.createIndex('value', 'value', {unique: true});
	}

	startUpdating() {
		if (this._authenticationService !== null) {
			this._authenticationService.unsubscribe();
		}
		this._authenticationService = this.authenticationService.isAuthenticated().subscribe(event => {
			if (event) {
				this.loadingOverlayService.show();
				if (this.debug) {
					console.log((new Date().getTime() - this.syncStart), 'Starting of pushing of data to server ...');
				}
				this.snackbarService.info('Synchronisatie is gestart, dit een moment duren ...');

				return Promise.all([
					this.pushToServer_ReportsCreate().then(() => {
						return Promise.all([
							this.pushToServer_ReportsUpdate().then(() => {
								return this.pushToServer_ReportsDelete().then(() => {
									return this.pushToServer_KVVMReportsUpdates().then(() => true);
								});
							})
						]).then(() => {
							return Promise.all([
								this.pushToServer_ReportsAttachmentsCopy().then(() => {
									return this.pushToServer_ReportsAttachmentsCreate().then(() => {
										return this.pushToServer_ReportsAttachmentsUpdate().then(() => {
											return this.pushToServer_ReportsAttachmentsDelete().then(() => true);
										});
									});
								}),
								this.pushToServer_ReportsCommentsCreate().then(() => {
									return this.pushToServer_ReportsCommentsUpdate().then(() => {
										return this.pushToServer_ReportsCommentsDelete().then(() => true);
									});
								}),
								this.pushToServer_ReportsBuildingsCreate().then(() => {
									return this.pushToServer_ReportsBuildingsUpdate().then(() => {
										return this.pushToServer_ReportsBuildingsDelete().then(() => {
											return this.pushToServer_ReportsBuildingsReportsCreate().then(() => {
												return this.pushToServer_ReportsBuildingsReportsUpdate().then(() => {
													return this.pushToServer_ReportsBuildingsReportsDelete().then(() => {
														return this.pushToServer_ReportsBuildingsReportsPhotosCreate().then(() => {
															return this.pushToServer_ReportsBuildingsReportsPhotosDelete().then(() => {
																return this.pushToServer_ReportsSendToClient().then(() => true);
															});
														});
													});
												});
											});
										});
									});
								})
							]);
						}).then(() => {
							return true;
						}).catch(error => new Error(error));
					})
				]).then(() => {
					if (this.debug) {
						console.log((new Date().getTime() - this.syncStart), 'pushToServer all done, starting sync ....');
					}
					this.syncAll(true);

					return true;
				}).catch(error => {
					this.loadingOverlayService.hide();
					throw new Error(error);
				});
			}
		});
	}

	removeOldData() {
		this.databaseReady.subscribe(event => {
			if (event) {
				setTimeout(() => {
					this.database.getAllFast(this.tableReports).then(reports => {
						let filtered = [],
							filtered_attachments = [],
							filtered_buildings = [],
							filtered_building_reports = [],
							filtered_comments = [],
							remove_up_to_date = moment().subtract('1 year').unix();

						if (typeof reports !== 'undefined') {
							reports.forEach(report => {
								if (report.created <= remove_up_to_date) {
									filtered.push(report.id);
								}
							});
						}
						if (filtered.length) {
							this.database.getAllFast(this.tableReportsAttachments).then(attachments => {
								if (typeof attachments !== 'undefined') {
									attachments.forEach(attachment => {
										if (filtered.indexOf(attachment.report_id) !== -1) {
											filtered_attachments.push(attachment.id);
										}
									});
									if (filtered_attachments.length) {
										this.database.deleteBulk(this.tableReportsAttachments, filtered_attachments).then(() => {
										});
									}
								}
							});
							this.database.getAllFast(this.tableReportsBuildings).then(buildings => {
								if (typeof buildings !== 'undefined') {
									buildings.forEach(building => {
										if (filtered.indexOf(building.report_id) !== -1) {
											filtered_buildings.push(building.id);
										}
									});
									if (filtered_buildings.length) {
										this.database.deleteBulk(this.tableReportsBuildings, filtered_buildings).then(() => {
										});
									}
								}
							});
							this.database.getAllFast(this.tableReportsBuildingsReports).then(building_reports => {
								if (typeof building_reports !== 'undefined') {
									building_reports.forEach(building_report => {
										if (filtered.indexOf(building_report.report_id) !== -1) {
											filtered_building_reports.push(building_report.id);
										}
									});
									if (filtered_building_reports.length) {
										this.database.deleteBulk(this.tableReportsBuildingsReports, filtered_building_reports).then(() => {
										});
									}
								}
							});
							this.database.getAllFast(this.tableReportsComments).then(comments => {
								if (typeof comments !== 'undefined') {
									comments.forEach(comment => {
										if (filtered.indexOf(comment.report_id) !== -1) {
											filtered_comments.push(comment.id);
										}
									});
									if (filtered_comments.length) {
										this.database.deleteBulk(this.tableReportsComments, filtered_comments).then(() => {
										});
									}
								}
							});
							this.database.deleteBulk(this.tableReports, filtered).then(() => {
							});
						}
					});

					/* KVVM Reports */
					this.database.getAllFast(this.tableKvvmReports).then(reports => {
						let filtered = [],
							filtered_attachments = [],
							filtered_buildings = [],
							filtered_building_reports = [],
							filtered_comments = [],
							remove_up_to_date = moment().subtract('1 year').unix();

						if (typeof reports !== 'undefined') {
							reports.forEach(report => {
								if (report.created <= remove_up_to_date) {
									filtered.push(report.id);
								}
							});
						}
						if (filtered.length) {
							this.database.getAllFast(this.tableKvvmReportsAttachments).then(attachments => {
								if (typeof attachments !== 'undefined') {
									attachments.forEach(attachment => {
										if (filtered.indexOf(attachment.report_id) !== -1) {
											filtered_attachments.push(attachment.id);
										}
									});
									if (filtered_attachments.length) {
										this.database.deleteBulk(this.tableKvvmReportsAttachments, filtered_attachments).then(() => {
										});
									}
								}
							});
							this.database.getAllFast(this.tableKvvmReportsBuildings).then(buildings => {
								if (typeof buildings !== 'undefined') {
									buildings.forEach(building => {
										if (filtered.indexOf(building.report_id) !== -1) {
											filtered_buildings.push(building.id);
										}
									});
									if (filtered_buildings.length) {
										this.database.deleteBulk(this.tableKvvmReportsBuildings, filtered_buildings).then(() => {
										});
									}
								}
							});
							this.database.getAllFast(this.tableKvvmReportsBuildingsReports).then(building_reports => {
								if (typeof building_reports !== 'undefined') {
									building_reports.forEach(building_report => {
										if (filtered.indexOf(building_report.report_id) !== -1) {
											filtered_building_reports.push(building_report.id);
										}
									});
									if (filtered_building_reports.length) {
										this.database.deleteBulk(this.tableKvvmReportsBuildingsReports, filtered_building_reports).then(() => {
										});
									}
								}
							});
							this.database.getAllFast(this.tableKvvmReportsComments).then(comments => {
								if (typeof comments !== 'undefined') {
									comments.forEach(comment => {
										if (filtered.indexOf(comment.report_id) !== -1) {
											filtered_comments.push(comment.id);
										}
									});
									if (filtered_comments.length) {
										this.database.deleteBulk(this.tableKvvmReportsComments, filtered_comments).then(() => {
										});
									}
								}
							});
							this.database.deleteBulk(this.tableKvvmReports, filtered).then(() => {
							});
						}
					});
				}, 2500);
			}
		});
	}

	syncAll(showMessage: boolean = false) {
		if (this._authenticationService !== null) {
			this._authenticationService.unsubscribe();
		}
		this._authenticationService = this.authenticationService.isAuthenticated().subscribe(event => {
			if (event) {
				if (this.debug) {
					console.log((new Date().getTime() - this.syncStart), 'starting of syncing of data ...');
				}
				return Promise.all([
					this.syncDeletedItems().then(() => {
						return Promise.all([
							this.syncCategories(),
							this.syncSubcategories(),
							this.syncTypes(),
							this.syncActions(),
							this.syncReports(),
							this.syncReportsAttachments(),
							this.syncReportsComments(),
							this.syncReportsBuildings(),
							this.syncReportsBuildingsReports(),
							this.syncKVVMReports(),
							this.syncKVVMReportsAttachments(),
							this.syncKVVMReportsComments(),
							this.syncKVVMReportsBuildings(),
							this.syncKVVMReportsBuildingsReports(),
							this.syncSettings(),
						]);
					})
				]).then(() => {
					if (this.debug) {
						console.log((new Date().getTime() - this.syncStart), 'sync done');
					}
					this.loadingOverlayService.hide();
					if (showMessage) {
						this.snackbarService.info('Synchronisatie voltooid!');
					}
					this.syncComplete.next(true);
				}).catch(() => {
					this.loadingOverlayService.hide();
				});
			}
		});
	}

	syncSettings(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.apiService.get('settings').then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									let start = new Date().getTime(),
										settings = [];
									Object.keys(data.data).map(key => {
										settings.push({
											name: key,
											value: data.data[key]
										});
									});
									promises.push(this.database.updateBulk(this.tableSettings, settings)
										.then(() => true)
										.catch(error => new Error(error))
									);

									return Promise.all(promises).then(() => {
										if (this.debug) {
											console.log((new Date().getTime() - start), 'syncSettings done');
										}
										resolve();
									});
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					} else {
						resolve();
					}
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncDeletedItems(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, 'deleted_items').then(user_device_date => {
					this.apiService.get('deleted-items/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('deleted-items', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime(),
																	reports = [],
																	reports_attachments = [],
																	reports_buildings = [],
																	reports_buildings_reports = [],
																	reports_buildings_reports_photos = [],
																	categories = [],
																	subcategories = [],
																	types = [],
																	actions = [],
																	category_subcategory_type = [],
																	category_subcategory_action = [];

																if (this.debug) {
																	console.log((new Date().getTime() - start), 'delete: started ...');
																}
																data.data.forEach(data2 => {
																	if (data2.rid !== null &&
																		data2.raid === null &&
																		data2.rbid === null &&
																		data2.rbrid === null &&
																		data2.rbrpid === null) {
																		reports.push({rid: data2.rid});
																	}
																	if (data2.rid !== null &&
																		data2.raid !== null &&
																		data2.rbid === null &&
																		data2.rbrid === null &&
																		data2.rbrpid === null) {
																		reports_attachments.push({rid: data2.rid, raid: data2.raid});
																	}
																	if (data2.rid !== null &&
																		data2.raid === null &&
																		data2.rbid !== null &&
																		data2.rbrid === null &&
																		data2.rbrpid === null) {
																		reports_buildings.push({rid: data2.rid, rbid: data2.rbid});
																	}
																	if (data2.rid !== null &&
																		data2.raid === null &&
																		data2.rbid !== null &&
																		data2.rbrid !== null &&
																		data2.rbrpid === null) {
																		reports_buildings_reports.push({
																			rid: data2.rid,
																			rbid: data2.rbid,
																			rbrid: data2.rbrid
																		});
																	}
																	if (data2.rid !== null &&
																		data2.raid === null &&
																		data2.rbid !== null &&
																		data2.rbrid !== null &&
																		data2.rbrpid !== null) {
																		reports_buildings_reports_photos.push({
																			rid: data2.rid,
																			rbid: data2.rbid,
																			rbrid: data2.rbrid,
																			rbrpid: data2.rbrpid
																		});
																	}
																	if (data2.wcid === null &&
																		data2.wsid === null &&
																		data2.waid !== null) {
																		actions.push({waid: data2.waid});
																	}
																	if (data2.wcid !== null &&
																		data2.wsid === null &&
																		data2.waid === null &&
																		data2.wtid === null) {
																		categories.push({wcid: data2.wcid});
																	}
																	if (data2.wcid !== null &&
																		data2.wsid !== null &&
																		data2.waid === null &&
																		data2.wtid === null) {
																		subcategories.push({wcid: data2.wcid, wsid: data2.wsid});
																	}
																	if (data2.wcid !== null &&
																		data2.wsid !== null &&
																		data2.waid !== null &&
																		data2.wtid === null) {
																		category_subcategory_action.push({
																			wcid: data2.wcid,
																			wsid: data2.wsid,
																			waid: data2.waid
																		});
																	}
																	if (data2.wcid !== null &&
																		data2.wsid !== null &&
																		data2.waid === null &&
																		data2.wtid !== null) {
																		category_subcategory_type.push({
																			wcid: data2.wcid,
																			wsid: data2.wsid,
																			wtid: data2.wtid
																		});
																	}
																	if (data2.wcid === null &&
																		data2.wsid === null &&
																		data2.wtid !== null) {
																		types.push({wtid: data2.wtid});
																	}
																});

																if (reports.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: reports start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteReport(null, reports)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: reports done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (reports_attachments.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: reports_attachments start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteReportAttachment(null, reports_attachments)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: reports_attachments done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (reports_buildings.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: reports_buildings start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteReportBuilding(null, reports_buildings)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: reports_buildings done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (reports_buildings_reports.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: reports_buildings_reports start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteReportBuildingReport(reports_buildings_reports)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: reports_buildings_reports done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (reports_buildings_reports_photos.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: reports_buildings_reports_photos start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteReportBuildingReportPhoto(null, reports_buildings_reports_photos)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: reports_buildings_reports_photos done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (categories.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: categories start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteCategory(categories)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: categories done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (subcategories.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: subcategories start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteSubcategory(null, subcategories)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: subcategories done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (types.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: types start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteType(types)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: types done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (actions.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: actions start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteAction(actions)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: actions done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (category_subcategory_type.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: category_subcategory_type start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteCategorySubcategoryType(category_subcategory_type)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: category_subcategory_type done');
																				}
																				resolve2();
																			});
																	}));
																}
																if (category_subcategory_action.length) {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: category_subcategory_action start');
																	}
																	promises.push(new Promise(resolve2 => {
																		this.deleteCategorySubcategoryAction(category_subcategory_action)
																			.then(() => {
																				if (this.debug) {
																					console.log((new Date().getTime() - start), 'delete: category_subcategory_action done');
																				}
																				resolve2();
																			});
																	}));
																}

																promises.push(new Promise(resolve2 => {
																	this.database.update(this.tableDataSync, {
																		name: 'deleted_items',
																		updated: server_date.data.updated
																	})
																		.then(() => {
																			resolve2();
																		})
																		.catch(error => new Error(error));
																}));

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'delete: finished');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncCategories(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsCategories).then(user_device_date => {
					this.apiService.get('reports/sync/elements/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/sync/elements', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncCategories start ...');
																}
																promises.push(this.database.clear(this.tableReportsCategories).then(() =>
																	this.database.updateBulk(this.tableReportsCategories, <ReportsShortcomingsCategories[]>data.data)
																		.then(() => true)
																		.catch(error => new Error(error))
																));
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsCategories,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncCategories done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncSubcategories(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsSubcategories).then(user_device_date => {
					this.apiService.get('reports/sync/elements/subelements/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/sync/elements/subelements', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncSubcategories start ...');
																}
																promises.push(this.database.clear(this.tableReportsSubcategories).then(() =>
																	this.database.updateBulk(this.tableReportsSubcategories, <ReportsShortcomingsSubcategories[]>data.data)
																		.then(() => true)
																		.catch(error => new Error(error))
																));
																promises.push(this.database.getAllFast(this.tableReportsTypes)
																	.then(types => {
																		if (typeof types !== 'undefined') {
																			if (types.length) {
																				let filtered = [];
																				types.forEach(type => {
																					data.data.forEach(server_type => {
																						if (type.category_id === server_type.category_id && type.subcategory_id === server_type.id) {
																							type.amounts = server_type.amounts;
																							filtered.push(type);
																						}
																					});
																				});
																				if (filtered.length) {
																					return this.database.updateBulk(this.tableReportsTypes, filtered)
																						.then(() => true)
																						.catch(error => new Error(error));
																				}
																			}
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsSubcategories,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncSubcategories done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncTypes(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsTypes).then(user_device_date => {
					this.apiService.get('reports/sync/elements/subelements/types/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/sync/elements/subelements/types', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncTypes start ...');
																}
																let filtered = [];
																data.data.forEach(type => {
																	type.type_name = type.type_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
																	filtered.push(type);
																});
																if (filtered.length) {
																	promises.push(this.database.updateBulk(this.tableReportsTypes, <ReportsShortcomingsTypes[]>filtered)
																		.then(() => true)
																		.catch(error => new Error(error))
																	);
																}
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsTypes,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncTypes done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncActions(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsActions).then(user_device_date => {
					this.apiService.get('reports/sync/elements/subelements/actions/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/sync/elements/subelements/actions', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncActions start ...');
																}
																let filtered = [];
																data.data.forEach(action => {
																	action.action_name = action.action_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
																	filtered.push(action);
																});
																if (filtered.length) {
																	promises.push(this.database.updateBulk(this.tableReportsActions, <ReportsShortcomingsActions[]>filtered)
																		.then(() => true)
																		.catch(error => new Error(error))
																	);
																}
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsActions,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncActions done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncReports(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReports).then(user_device_date => {
					this.apiService.get('reports/sync/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/sync', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncReports start ...');
																}
																promises.push(this.database.updateBulk(this.tableReports, <Reports[]>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReports,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncReports done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncReportsAttachments(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsAttachments).then(user_device_date => {
					this.apiService.get('reports/sync/attachments/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/sync/attachments', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncReportsAttachments start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsAttachments, <ReportsAttachments[]>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsAttachments,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncReportsAttachments done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncReportsComments(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsComments).then(user_device_date => {
					this.apiService.get('reports/sync/comments/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/sync/comments', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncReportsComments start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsComments, <ReportsComments[]>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsComments,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncReportsComments done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncReportsBuildings(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsBuildings).then(user_device_date => {
					this.apiService.get('reports/sync/buildings/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/sync/buildings', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncReportsBuildings start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsBuildings, <ReportsBuildings[]>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsBuildings,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncReportsBuildings done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncReportsBuildingsReports(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableReportsBuildingsReports).then(user_device_date => {
					this.apiService.get('reports/sync/buildings/reports/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('reports/sync/buildings/reports', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncReportsBuildingsReports start ...');
																}
																promises.push(this.database.updateBulk(this.tableReportsBuildingsReports, <ReportsBuildingsReports[]>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableReportsBuildingsReports,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncReportsBuildingsReports done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	pushToServer_ReportsCreate(): Promise<void> {
		return new Promise(resolve => {
			let collectionData: Reports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsPush).then(reports => {
				if (typeof reports !== 'undefined') {
					if (reports.length) {
						reports.forEach(report => {
							if (report.indexedDBMethod === '1-create') {
								collectionData.push(report);
							}
						});
						if (collectionData.length) {
							collectionData.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports', report, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														if (typeof data.data.id !== 'undefined') {
															let promises2 = [];
															promises2.push(this.database.delete(this.tableReportsPush, report.entryId)
																.then(() => true)
																.catch(error => new Error(error))
															);
															if (data.data.id !== report.id) {
																let newId = parseInt(data.data.id, 10);
																promises2.push(this.database.delete(this.tableReports, report.id)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.add(this.tableReports, <Reports[]>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.getAllFast(this.tableReportsPush)
																	.then(reports2 => {
																		let filtered = [];
																		reports2.forEach(report2 => {
																			if (report2.id === report.id) {
																				report2.id = newId;
																				filtered.push(report2);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsPush, <Reports[]>filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.getAllFast(this.tableReportsAttachments)
																	.then(report_attachments => {
																		let filtered = [];
																		report_attachments.forEach(report_attachment => {
																			if (report_attachment.report_id === report.id) {
																				report_attachment.report_id = newId;
																				filtered.push(report_attachment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsAttachments, <ReportsAttachments[]>filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.getAllFast(this.tableReportsAttachmentsPush)
																	.then(report_attachments => {
																		let filtered = [];
																		report_attachments.forEach(report_attachment => {
																			if (report_attachment.report_id === report.id) {
																				report_attachment.report_id = newId;
																				filtered.push(report_attachment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsAttachmentsPush, <ReportsAttachments[]>filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.getAllFast(this.tableReportsComments)
																	.then(report_comments => {
																		let filtered = [];
																		report_comments.forEach(report_comment => {
																			if (report_comment.report_id === report.id) {
																				report_comment.report_id = newId;
																				filtered.push(report_comment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsComments, <ReportsComments[]>filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.getAllFast(this.tableReportsCommentsPush)
																	.then(report_comments => {
																		let filtered = [];
																		report_comments.forEach(report_comment => {
																			if (report_comment.report_id === report.id) {
																				report_comment.report_id = newId;
																				filtered.push(report_comment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsCommentsPush, <ReportsComments[]>filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.getAllFast(this.tableReportsBuildings)
																	.then(report_buildings => {
																		let filtered = [];
																		report_buildings.forEach(report_building => {
																			if (report_building.report_id === report.id) {
																				report_building.report_id = newId;
																				filtered.push(report_building);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsBuildings, <ReportsBuildings[]>filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.getAllFast(this.tableReportsBuildingsPush)
																	.then(report_buildings => {
																		let filtered = [];
																		report_buildings.forEach(report_building => {
																			if (report_building.report_id === report.id) {
																				report_building.report_id = newId;
																				filtered.push(report_building);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsBuildingsPush, <ReportsBuildings[]>filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.getAllFast(this.tableReportsBuildingsReports)
																	.then(report_buildings_reports => {
																		let filtered = [];
																		report_buildings_reports.forEach(report_building_report => {
																			if (report_building_report.report_id === report.id) {
																				report_building_report.report_id = newId;
																				filtered.push(report_building_report);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsBuildingsReports, <ReportsBuildingsReports[]>filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
																	.then(report_buildings_reports => {
																		let filtered = [];
																		report_buildings_reports.forEach(report_building_report => {
																			if (report_building_report.report_id === report.id) {
																				report_building_report.report_id = newId;
																				filtered.push(report_building_report);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsBuildingsReportsPush, <ReportsBuildingsReports[]>filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPhotos)
																	.then(report_buildings_reports_photos => {
																		let filtered = [];
																		report_buildings_reports_photos.forEach(report_building_report_photo => {
																			if (report_building_report_photo.report_id === report.id) {
																				report_building_report_photo.report_id = newId;
																				filtered.push(report_building_report_photo);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsBuildingsReportsPhotos, filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.getAllFast(this.tableReportsSendToClient)
																	.then(reports_send_to_client => {
																		let filtered = [];
																		reports_send_to_client.forEach(report_send_to_client => {
																			if (report_send_to_client.report_id === report.id) {
																				report_send_to_client.report_id = newId;
																				filtered.push(report_send_to_client);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsSendToClient, filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
															} else {
																promises2.push(this.database.update(this.tableReports, <Reports>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
															}

															return Promise.all(promises2).then(() => {
																this.redirectUser({
																	report_id: data.data.id,
																	old_report_id: report.id
																});
																resolve2();
															});
														} else {
															resolve2();
														}
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let collectionData: Reports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate start ...');
			}

			return this.database.getAllFast(this.tableReportsPush).then(reports => {
				if (typeof reports !== 'undefined') {
					if (reports.length) {
						reports.forEach(report => {
							if (report.indexedDBMethod === '2-update') {
								collectionData.push(report);
							}
						});
						if (collectionData.length) {
							collectionData.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + report.id, report, false).then((data: ServerResponse) => {
											if (data.success === true) {
												let promises2 = [];
												promises2.push(this.database.update(this.tableReports, data.data)
													.then(() => true)
													.catch(error => new Error(error))
												);
												promises2.push(this.database.delete(this.tableReportsPush, report.entryId)
													.then(() => true)
													.catch(error => new Error(error))
												);

												return Promise.all(promises2).then(() => {
													resolve2();
												});
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsDelete(): Promise<void> {
		return new Promise(resolve => {
			let collectionData: Reports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsPush).then(reports => {
				if (typeof reports !== 'undefined') {
					if (reports.length) {
						reports.forEach(report => {
							if (report.indexedDBMethod === '3-delete') {
								collectionData.push(report);
							}
						});
						if (collectionData.length) {
							collectionData.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + report.id, false).then((data: ServerResponse) => {
											if (data.success === true) {
												let promises2 = [];

												promises2.push(this.database.delete(this.tableReports, report.id)
													.then(() => true)
													.catch(error => new Error(error))
												);
												promises2.push(this.database.delete(this.tableReportsPush, report.entryId)
													.then(() => true)
													.catch(error => new Error(error))
												);

												return Promise.all(promises2).then(() => {
													resolve2();
												});
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsAttachmentsCopy(): Promise<void> {
		return new Promise(resolve => {
			let reportsAttachments: ReportsAttachments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy start ...');
			}

			return this.database.getAllFast(this.tableReportsAttachmentsPush).then(reports_attachments => {
				if (typeof reports_attachments !== 'undefined') {
					if (reports_attachments.length) {
						reports_attachments.forEach(report_attachment => {
							if (report_attachment.indexedDBMethod === '0-copy') {
								reportsAttachments.push(report_attachment);
							}
						});
						if (reportsAttachments.length) {
							reportsAttachments.forEach(attachment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.get('reports/' + attachment.copy_from_report_id + '/attachments/' + attachment.copy_from_attachment_id + '/copy/' + attachment.report_id, null, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.delete(this.tableReportsAttachmentsPush, attachment.entryId)
															.then(() => true)
															.catch(error => new Error(error))
														);
														promises2.push(this.database.delete(this.tableReportsAttachments, attachment.id)
															.then(() => true)
															.catch(error => new Error(error))
														);
														promises2.push(this.database.add(this.tableReportsAttachments, data.data)
															.then(() => true)
															.catch(error => new Error(error))
														);

														let newId = parseInt(data.data.id, 10);

														promises2.push(this.database.getAllFast(this.tableReportsAttachmentsPush)
															.then(reports_attachments2 => {
																let filtered = [];
																reports_attachments2.forEach(report_attachment => {
																	if (report_attachment.id === attachment.id) {
																		report_attachment.id = newId;
																		filtered.push(report_attachment);
																	}
																});
																if (filtered.length) {
																	return this.database.updateBulk(this.tableReportsAttachmentsPush, filtered)
																		.then(() => true)
																		.catch(error => new Error(error));
																}
																return true;
															})
															.catch(error => new Error(error))
														);

														return Promise.all(promises2).then(() => {
															this.redirectUser({
																report_id: data.data.report_id,
																attachment_id: data.data.id
															});
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsAttachmentsCreate(): Promise<void> {
		return new Promise(resolve => {
			let reportsAttachments: ReportsAttachments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsAttachmentsPush).then(reports_attachments => {
				if (typeof reports_attachments !== 'undefined') {
					if (reports_attachments.length) {
						reports_attachments.forEach(report_attachment => {
							if (report_attachment.indexedDBMethod === '1-create') {
								reportsAttachments.push(report_attachment);
							}
						});
						if (reportsAttachments.length) {
							reportsAttachments.forEach(attachment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										let newAttachment = {
											filename: new FileInput(attachment.filename['_files']),
											title: attachment.title,
											description: attachment.description

										};
										this.apiService.post('reports/' + attachment.report_id + '/attachments', newAttachment, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														if (typeof data.data.id !== 'undefined') {
															let promises2 = [];

															promises2.push(this.database.delete(this.tableReportsAttachmentsPush, attachment.entryId)
																.then(() => true)
																.catch(error => new Error(error))
															);
															if (data.data.id !== attachment.id) {
																promises2.push(this.database.delete(this.tableReportsAttachments, attachment.id)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises2.push(this.database.add(this.tableReportsAttachments, <ReportsAttachments>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);

																let newId = parseInt(data.data.id, 10);

																promises2.push(this.database.getAllFast(this.tableReportsAttachmentsPush)
																	.then(reports_attachments2 => {
																		let filtered = [];
																		reports_attachments2.forEach(report_attachment => {
																			if (report_attachment.id === attachment.id) {
																				report_attachment.id = newId;
																				filtered.push(report_attachment);
																			}
																		});
																		if (filtered.length) {
																			return this.database.updateBulk(this.tableReportsAttachmentsPush, <ReportsAttachments[]>filtered)
																				.then(() => true)
																				.catch(error => new Error(error));
																		}
																		return true;
																	})
																	.catch(error => new Error(error))
																);
															} else {
																promises2.push(this.database.update(this.tableReportsAttachments, <ReportsAttachments>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
															}

															return Promise.all(promises2).then(() => {
																this.redirectUser({
																	report_id: data.data.report_id,
																	attachment_id: data.data.id
																});
																resolve2();
															});
														} else {
															resolve2();
														}
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsAttachmentsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let reportsAttachments: ReportsAttachments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate start ...');
			}

			return this.database.getAllFast(this.tableReportsAttachmentsPush).then(reports_attachments => {
				if (typeof reports_attachments !== 'undefined') {
					if (reports_attachments.length) {
						reports_attachments.forEach(report_attachment => {
							if (report_attachment.indexedDBMethod === '2-update') {
								reportsAttachments.push(report_attachment);
							}
						});
						if (reportsAttachments.length) {
							reportsAttachments.forEach(attachment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + attachment.report_id + '/attachments/' + attachment.id, attachment, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.update(this.tableReportsAttachments, <ReportsAttachments>data.data)
															.then(() => true)
															.catch(error => new Error(error))
														);
														promises2.push(this.database.delete(this.tableReportsAttachmentsPush, attachment.entryId)
															.then(() => true)
															.catch(error => new Error(error))
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsAttachmentsDelete(): Promise<void> {
		return new Promise(resolve => {
			let reportsAttachments: ReportsAttachments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsAttachmentsPush).then(reports_attachments => {
				if (typeof reports_attachments !== 'undefined') {
					if (reports_attachments.length) {
						reports_attachments.forEach(report_attachment => {
							if (report_attachment.indexedDBMethod === '3-delete') {
								reportsAttachments.push(report_attachment);
							}
						});
						if (reportsAttachments.length) {
							reportsAttachments.forEach(attachment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + attachment.report_id + '/attachments/' + attachment.id, false).then(() => {
											let promises2 = [];

											promises2.push(this.database.delete(this.tableReportsAttachments, attachment.id)
												.then(() => true)
												.catch(error => new Error(error))
											);
											promises2.push(this.database.delete(this.tableReportsAttachmentsPush, attachment.entryId)
												.then(() => true)
												.catch(error => new Error(error))
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsCommentsCreate(): Promise<void> {
		return new Promise(resolve => {
			let commentsData: ReportsComments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsCommentsPush).then(reports_comments => {
				if (typeof reports_comments !== 'undefined') {
					if (reports_comments.length) {
						reports_comments.forEach(report_comment => {
							if (report_comment.indexedDBMethod === '1-create') {
								commentsData.push(report_comment);
							}
						});
						if (commentsData.length) {
							commentsData.forEach(comment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + comment.report_id + '/comments', comment, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];
														promises2.push(this.database.delete(this.tableReportsCommentsPush, comment.entryId)
															.then(() => true)
															.catch(error => new Error(error))
														);
														if (data.data.id !== comment['id']) {
															promises2.push(this.database.delete(this.tableReportsComments, comment.id)
																.then(() => true)
																.catch(error => new Error(error))
															);
															promises2.push(this.database.add(this.tableReportsComments, <ReportsComments>data.data)
																.then(() => true)
																.catch(error => new Error(error))
															);

															let newId = parseInt(data.data.id, 10);

															promises2.push(this.database.getAllFast(this.tableReportsCommentsPush)
																.then(reports_comments2 => {
																	let filtered = [];
																	reports_comments2.forEach(report_comment => {
																		if (report_comment.id === comment.id) {
																			report_comment.id = newId;
																			filtered.push(report_comment);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsCommentsPush, filtered)
																			.then(() => true)
																			.catch(error => new Error(error));
																	}
																	return true;
																})
																.catch(error => new Error(error))
															);
														} else {
															promises2.push(this.database.update(this.tableReportsComments, <ReportsComments>data.data)
																.then(() => true)
																.catch(error => new Error(error))
															);
														}

														return Promise.all(promises2).then(() => {
															this.redirectUser({
																report_id: data.data.report_id,
																comment_id: data.data.id
															});
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsCommentsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let commentsData: ReportsComments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate start ...');
			}

			return this.database.getAllFast(this.tableReportsCommentsPush).then(reports_comments => {
				if (typeof reports_comments !== 'undefined') {
					if (reports_comments.length) {
						reports_comments.forEach(report_comment => {
							if (report_comment.indexedDBMethod === '2-update') {
								commentsData.push(report_comment);
							}
						});
						if (commentsData.length) {
							commentsData.forEach(comment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + comment.report_id + '/comments/' + comment.id, comment, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													let promises2 = [];
													promises2.push(this.database.update(this.tableReportsComments, <ReportsComments>data.data)
														.then(() => true)
														.catch(error => new Error(error))
													);
													promises2.push(this.database.delete(this.tableReportsCommentsPush, comment.entryId)
														.then(() => true)
														.catch(error => new Error(error))
													);

													return Promise.all(promises2).then(() => {
														resolve2();
													});
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsCommentsDelete(): Promise<void> {
		return new Promise(resolve => {
			let commentsData: ReportsComments[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsCommentsPush).then(reports_comments => {
				if (typeof reports_comments !== 'undefined') {
					if (reports_comments.length) {
						reports_comments.forEach(report_comment => {
							if (report_comment.indexedDBMethod === '3-delete') {
								commentsData.push(report_comment);
							}
						});
						if (commentsData.length) {
							commentsData.forEach(comment => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + comment.report_id + '/comments/' + comment.id, false).then(() => {
											let promises2 = [];
											promises2.push(this.database.delete(this.tableReportsComments, comment.id)
												.then(() => true)
												.catch(error => new Error(error))
											);
											promises2.push(this.database.delete(this.tableReportsCommentsPush, comment.entryId)
												.then(() => true)
												.catch(error => new Error(error))
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsBuildingsCreate(): Promise<void> {
		return new Promise(resolve => {
			let buildingsData: ReportsBuildings[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsPush).then(reports_buildings => {
				if (typeof reports_buildings !== 'undefined') {
					if (reports_buildings.length) {
						reports_buildings.forEach(report_building => {
							if (report_building.indexedDBMethod === '1-create') {
								buildingsData.push(report_building);
							}
						});
						if (buildingsData.length) {
							buildingsData.forEach(building => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + building.report_id + '/buildings', building, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [],
															newId = parseInt(data.data.id, 10);

														promises2.push(this.database.delete(this.tableReportsBuildingsPush, building.entryId)
															.then(() => true)
															.catch(error => new Error(error))
														);

														if (data.data.id !== building.id) {
															promises2.push(this.database.delete(this.tableReportsBuildings, building.id)
																.then(() => true)
																.catch(error => new Error(error))
															);
															promises2.push(this.database.add(this.tableReportsBuildings, data.data)
																.then(() => true)
																.catch(error => new Error(error))
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsPush)
																.then(reports_buildings2 => {
																	let filtered = [];
																	reports_buildings2.forEach(report_building => {
																		if (report_building.id === building.id) {
																			report_building.id = newId;
																			filtered.push(report_building);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsPush, filtered)
																			.then(() => true)
																			.catch(error => new Error(error));
																	}
																	return true;
																})
																.catch(error => new Error(error))
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsReports)
																.then(reports_buildings_reports => {
																	let filtered = [];
																	reports_buildings_reports.forEach(report_buildings_report => {
																		if (report_buildings_report.building_id === building.id) {
																			report_buildings_report.building_id = newId;
																			filtered.push(report_buildings_report);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsReports, filtered)
																			.then(() => true)
																			.catch(error => new Error(error));
																	}
																	return true;
																})
																.catch(error => new Error(error))
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
																.then(reports_buildings_reports => {
																	let filtered = [];
																	reports_buildings_reports.forEach(report_buildings_report => {
																		if (report_buildings_report.building_id === building.id) {
																			report_buildings_report.building_id = newId;
																			filtered.push(report_buildings_report);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsReportsPush, filtered)
																			.then(() => true)
																			.catch(error => new Error(error));
																	}
																	return true;
																})
																.catch(error => new Error(error))
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPhotos)
																.then(reports_buildings_reports_photos => {
																	let filtered = [];
																	reports_buildings_reports_photos.forEach(report_building_report_photo => {
																		if (report_building_report_photo.building_id === building.id) {
																			report_building_report_photo.building_id = newId;
																			filtered.push(report_building_report_photo);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsReportsPhotos, filtered)
																			.then(() => true)
																			.catch(error => new Error(error));
																	}
																	return true;
																})
																.catch(error => new Error(error))
															);
														} else {
															promises2.push(this.database.update(this.tableReportsBuildings, data.data)
																.then(() => true)
																.catch(error => new Error(error))
															);
														}

														return Promise.all(promises2).then(() => {
															this.redirectUser({
																report_id: data.data.report_id,
																building_id: data.data.id,
																old_building_id: building.id,
																old_report_id: building.report_id
															});
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsBuildingsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let buildingsData: ReportsBuildings[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsPush).then(reports_buildings => {
				if (typeof reports_buildings !== 'undefined') {
					if (reports_buildings.length) {
						reports_buildings.forEach(report_building => {
							if (report_building.indexedDBMethod === '2-update') {
								buildingsData.push(report_building);
							}
						});
						if (buildingsData.length) {
							buildingsData.forEach(building => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.post('reports/' + building.report_id + '/buildings/' + building.id, building, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];
														promises2.push(this.database.delete(this.tableReportsBuildingsPush, building.entryId)
															.then(() => true)
															.catch(error => new Error(error))
														);
														promises2.push(this.database.update(this.tableReportsBuildings, data.data)
															.then(() => true)
															.catch(error => new Error(error))
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsBuildingsDelete(): Promise<void> {
		return new Promise(resolve => {
			let buildingsData: ReportsBuildings[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsPush).then(reports_buildings => {
				if (typeof reports_buildings !== 'undefined') {
					if (reports_buildings.length) {
						reports_buildings.forEach(report_building => {
							if (report_building.indexedDBMethod === '3-delete') {
								buildingsData.push(report_building);
							}
						});
						if (buildingsData.length) {
							buildingsData.forEach(building => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + building.report_id + '/buildings/' + building.id, false).then(() => {
											let promises2 = [];
											promises2.push(this.database.delete(this.tableReportsBuildings, building.id)
												.then(() => true)
												.catch(error => new Error(error))
											);
											promises2.push(this.database.delete(this.tableReportsBuildingsPush, building.entryId)
												.then(() => true)
												.catch(error => new Error(error))
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsBuildingsReportsCreate(): Promise<void> {
		return new Promise(resolve => {
			let buildingReport: ReportsBuildingsReports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsReportsPush).then(reports_buildings_reports => {
				if (typeof reports_buildings_reports !== 'undefined') {
					if (reports_buildings_reports.length) {
						reports_buildings_reports.forEach(report_building_report => {
							if (report_building_report.indexedDBMethod === '1-create') {
								buildingReport.push(report_building_report);
							}
						});
						if (buildingReport.length) {
							buildingReport.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										delete report.photos;
										this.apiService.post('reports/' + report.report_id + '/buildings/' + report.building_id + '/reports', report, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [],
															newId = parseInt(data.data.id, 10);

														promises2.push(this.database.delete(this.tableReportsBuildingsReportsPush, report.entryId)
															.then(() => true)
															.catch(error => new Error(error))
														);
														if (data.data.id !== report.id) {
															promises2.push(this.database.delete(this.tableReportsBuildingsReports, report.id)
																.then(() => true)
																.catch(error => new Error(error))
															);
															promises2.push(this.database.add(this.tableReportsBuildingsReports, data.data)
																.then(() => true)
																.catch(error => new Error(error))
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
																.then(reports_building_reports2 => {
																	let filtered = [];
																	reports_building_reports2.forEach(report_building_report => {
																		if (report_building_report.id === report.id) {
																			report_building_report.id = newId;
																			filtered.push(report_building_report);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsReportsPush, filtered)
																			.then(() => true)
																			.catch(error => new Error(error));
																	}
																	return true;
																})
																.catch(error => new Error(error))
															);
															promises2.push(this.database.getAllFast(this.tableReportsBuildingsReportsPhotos)
																.then(reports_buildings_reports_photos => {
																	let filtered = [];
																	reports_buildings_reports_photos.forEach(report_building_report_photo => {
																		if (report_building_report_photo.building_report_id === report.id) {
																			report_building_report_photo.building_report_id = newId;
																			filtered.push(report_building_report_photo);
																		}
																	});
																	if (filtered.length) {
																		return this.database.updateBulk(this.tableReportsBuildingsReportsPhotos, filtered)
																			.then(() => true)
																			.catch(error => new Error(error));
																	}
																	return true;
																})
																.catch(error => new Error(error))
															);
														} else {
															promises2.push(this.database.update(this.tableReportsBuildingsReports, data.data)
																.then(() => true)
																.catch(error => new Error(error))
															);
														}

														return Promise.all(promises2).then(() => {
															this.redirectUser({
																report_id: data.data.report_id,
																building_id: data.data.building_id,
																building_report_id: data.data.id,
																old_report_id: report.report_id,
																old_building_id: report.building_id,
																old_building_report_id: report.id
															});
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsBuildingsReportsUpdate(): Promise<void> {
		return new Promise(resolve => {
			let buildingReport: ReportsBuildingsReports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsReportsPush).then(reports_buildings_reports => {
				if (typeof reports_buildings_reports !== 'undefined') {
					if (reports_buildings_reports.length) {
						reports_buildings_reports.forEach(report_building_report => {
							if (report_building_report.indexedDBMethod === '2-update') {
								buildingReport.push(report_building_report);
							}
						});
						if (buildingReport.length) {
							buildingReport.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										delete report.photos;
										this.apiService.post('reports/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.id, report, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.update(this.tableReportsBuildingsReports, data.data)
															.then(() => true)
															.catch(error => new Error(error))
														);
														promises2.push(this.database.delete(this.tableReportsBuildingsReportsPush, report.entryId)
															.then(() => true)
															.catch(error => new Error(error))
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsBuildingsReportsDelete(): Promise<void> {
		return new Promise(resolve => {
			let buildingReport: ReportsBuildingsReports[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsReportsPush).then(reports_buildings_reports => {
				if (typeof reports_buildings_reports !== 'undefined') {
					if (reports_buildings_reports.length) {
						reports_buildings_reports.forEach(report_building_report => {
							if (report_building_report.indexedDBMethod === '3-delete') {
								buildingReport.push(report_building_report);
							}
						});
						if (buildingReport.length) {
							buildingReport.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.id, false).then(() => {
											let promises2 = [];

											promises2.push(this.database.delete(this.tableReportsBuildingsReports, report.id)
												.then(() => true)
												.catch(error => new Error(error))
											);
											promises2.push(this.database.delete(this.tableReportsBuildingsReportsPush, report.entryId)
												.then(() => true)
												.catch(error => new Error(error))
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsBuildingsReportsPhotosCreate(): Promise<void> {
		return new Promise(resolve => {
			let buildingReport: any[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsReportsPhotos).then(reports_buildings_reports_photos => {
				if (typeof reports_buildings_reports_photos !== 'undefined') {
					if (reports_buildings_reports_photos.length) {
						reports_buildings_reports_photos.forEach(report_building_report_photo => {
							if (report_building_report_photo.indexedDBMethod === '1-create') {
								buildingReport.push(report_building_report_photo);
							}
						});
						if (buildingReport.length) {
							buildingReport.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										let photos = {
											photos: []
										};
										report.photos.forEach(photo => {
											photos.photos.push(photo.file);
										});
										this.apiService.post('reports/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id + '/photos', photos, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.delete(this.tableReportsBuildingsReportsPhotos, report.id)
															.then(() => true)
															.catch(error => new Error(error))
														);
														promises2.push(this.database.update(this.tableReportsBuildingsReports, data.data)
															.then(() => true)
															.catch(error => new Error(error))
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsBuildingsReportsPhotosDelete(): Promise<void> {
		return new Promise(resolve => {
			let buildingReport: any[] = [],
				promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete start ...');
			}

			return this.database.getAllFast(this.tableReportsBuildingsReportsPhotos).then(reports_buildings_reports_photos => {
				if (typeof reports_buildings_reports_photos !== 'undefined') {
					if (reports_buildings_reports_photos.length) {
						reports_buildings_reports_photos.forEach(report_building_report_photo => {
							if (report_building_report_photo.indexedDBMethod === '3-delete') {
								buildingReport.push(report_building_report_photo);
							}
						});
						if (buildingReport.length) {
							buildingReport.forEach(report => {
								promises.push(new Promise(resolve2 => {
									if (this.apiService.isOnline) {
										this.apiService.delete('reports/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id + '/delete-image/' + report.id, false).then((data: ServerResponse) => {
											if (typeof data !== 'undefined') {
												if (data.success === true) {
													if (typeof data.data !== 'undefined') {
														let promises2 = [];

														promises2.push(this.database.delete(this.tableReportsBuildingsReportsPhotos, report.id)
															.then(() => true)
															.catch(error => new Error(error))
														);
														promises2.push(this.database.update(this.tableReportsBuildingsReports, data.data)
															.then(() => true)
															.catch(error => new Error(error))
														);

														return Promise.all(promises2).then(() => {
															resolve2();
														});
													} else {
														resolve2();
													}
												} else {
													resolve2();
												}
											} else {
												resolve2();
											}
										}).catch(error => new Error(error));
									} else {
										resolve2();
									}
								}));
							});

							return Promise.all(promises).then(() => {
								if (this.debug) {
									console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete done');
								}
								resolve();
							});
						} else {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
							}
							resolve();
						}
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	pushToServer_ReportsSendToClient(): Promise<void> {
		return new Promise(resolve => {
			let promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient start ...');
			}

			return this.database.getAllFast(this.tableReportsSendToClient).then(reports_send_to_client => {
				if (typeof reports_send_to_client !== 'undefined') {
					if (reports_send_to_client.length) {
						reports_send_to_client.forEach(report => {
							promises.push(new Promise(resolve2 => {
								if (this.apiService.isOnline) {
									return this.apiService.post('reports/' + report.report_id + '/send-to-email', report, false)
										.then(() => {
											return this.database.delete(this.tableReportsSendToClient, report.entryId)
												.then(() => resolve2())
												.catch(error => new Error(error));
										})
										.catch(error => new Error(error));
								} else {
									resolve2();
								}
							}));
						});

						return Promise.all(promises).then(() => {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient done');
							}
							resolve();
						});
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	deleteReport(client_ids: Array<{ cid: number }> = null, report_ids: Array<{ rid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (client_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReports)
					.then(reports => {
						if (typeof reports !== 'undefined') {
							if (reports.length) {
								let filtered = [];
								reports.forEach(report => {
									client_ids.forEach(client_id => {
										if (report.client_id === client_id.cid) {
											filtered.push(report.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReports, filtered)
										.then(() => {
											let promises2 = [],
												rids = filtered.map(report => {
													return {rid: report.id};
												});
											promises2.push(this.deleteReportAttachment(rids));
											promises2.push(this.deleteReportComment(rids));
											promises2.push(this.deleteReportSendToClient(client_ids));
											promises2.push(this.deleteReportBuilding(rids));
											promises2.push(this.deleteReportBuildingReport(rids));
											promises2.push(this.deleteReportBuildingReportPhoto(rids));

											return Promise.all(promises2).then(() => true);
										})
										.catch(error => new Error(error));

								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsPush)
					.then(reports => {
						if (typeof reports !== 'undefined') {
							if (reports.length) {
								let filtered = [];
								reports.forEach(report => {
									client_ids.forEach(client_id => {
										if (report.client_id === client_id.cid) {
											filtered.push(report.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsPush, filtered)
										.then(() => {
											let promises2 = [],
												rids = filtered.map(report => {
													return {rid: report.id};
												});
											promises2.push(this.deleteReportAttachment(rids));
											promises2.push(this.deleteReportComment(rids));
											promises2.push(this.deleteReportBuilding(rids));
											promises2.push(this.deleteReportBuildingReport(rids));
											promises2.push(this.deleteReportBuildingReportPhoto(rids));

											return Promise.all(promises2).then(() => true);
										})
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReports, report_ids.map(ids => ids.rid))
					.then(() => true)
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsPush)
					.then(reports => {
						if (typeof reports !== 'undefined') {
							if (reports.length) {
								let filtered = [];
								reports.forEach(report => {
									report_ids.forEach(report_id => {
										if (report.id === report_id.rid) {
											filtered.push(report.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsPush, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);

				promises.push(this.deleteReportAttachment(report_ids));
				promises.push(this.deleteReportComment(report_ids));
				promises.push(this.deleteReportBuilding(report_ids));
				promises.push(this.deleteReportBuildingReport(report_ids));
				promises.push(this.deleteReportBuildingReportPhoto(report_ids));
				promises.push(this.deleteReportSendToClient(null, report_ids));
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportAttachment(report_ids: Array<{ rid: number }>, attachment_ids: Array<{ rid: number, raid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (attachment_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsAttachments, attachment_ids.map(ids => ids.raid))
					.then(() => true)
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsAttachmentsPush)
					.then(attachments => {
						if (typeof attachments !== 'undefined') {
							if (attachments.length) {
								let filtered = [];
								attachments.forEach(attachment => {
									attachment_ids.forEach(attachment_id => {
										if (attachment.report_id === attachment_id.rid && attachment.id === attachment_id.raid) {
											filtered.push(attachment.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsAttachmentsPush, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsAttachments)
					.then(attachments => {
						if (typeof attachments !== 'undefined') {
							if (attachments.length) {
								let filtered = [];
								attachments.forEach(attachment => {
									report_ids.forEach(report_id => {
										if (attachment.report_id === report_id.rid) {
											filtered.push(attachment.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsAttachments, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsAttachmentsPush)
					.then(attachments => {
						if (typeof attachments !== 'undefined') {
							if (attachments.length) {
								let filtered = [];
								attachments.forEach(attachment => {
									report_ids.forEach(report_id => {
										if (attachment.report_id === report_id.rid) {
											filtered.push(attachment.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsAttachmentsPush, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportComment(report_ids: Array<{ rid: number }>, comment_ids: Array<{ rid: number, rcid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (comment_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsComments, comment_ids.map(ids => ids.rcid))
					.then(() => true)
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsCommentsPush)
					.then(comments => {
						if (typeof comments !== 'undefined') {
							if (comments.length) {
								let filtered = [];
								comments.forEach(comment => {
									comment_ids.forEach(comment_id => {
										if (comment.id === comment_id.rcid) {
											filtered.push(comment.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsCommentsPush, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsComments)
					.then(comments => {
						if (typeof comments !== 'undefined') {
							if (comments.length) {
								let filtered = [];
								comments.forEach(comment => {
									report_ids.forEach(report_id => {
										if (comment.report_id === report_id.rid) {
											filtered.push(comment.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsComments, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsCommentsPush)
					.then(comments => {
						if (typeof comments !== 'undefined') {
							if (comments.length) {
								let filtered = [];
								comments.forEach(comment => {
									report_ids.forEach(report_id => {
										if (comment.report_id === report_id.rid) {
											filtered.push(comment.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsCommentsPush, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportBuilding(report_ids: Array<{ rid: number }>, building_ids: Array<{ rid: number, rbid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (building_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsBuildings, building_ids.map(ids => ids.rbid))
					.then(() => true)
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsBuildingsPush)
					.then(report_buildings => {
						if (typeof report_buildings !== 'undefined') {
							if (report_buildings.length) {
								let filtered = [];
								report_buildings.forEach(report_building => {
									building_ids.forEach(building_id => {
										if (report_building.id === building_id.rbid) {
											filtered.push(report_building.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsPush, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
				);

				promises.push(this.deleteReportBuildingReport(null, building_ids));
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsBuildings)
					.then(report_buildings => {
						if (typeof report_buildings !== 'undefined') {
							if (report_buildings.length) {
								let filtered = [];
								report_buildings.forEach(report_building => {
									report_ids.forEach(report_id => {
										if (report_building.report_id === report_id.rid) {
											filtered.push(report_building.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildings, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsBuildingsPush)
					.then(report_buildings => {
						if (typeof report_buildings !== 'undefined') {
							if (report_buildings.length) {
								let filtered = [];
								report_buildings.forEach(building_report => {
									report_ids.forEach(report_id => {
										if (building_report.report_id === report_id.rid) {
											filtered.push(building_report.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsPush, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportBuildingReport(report_ids: Array<{ rid: number }>, building_ids: Array<{
		rid: number,
		rbid: number
	}> = null, building_report_ids: Array<{ rid: number, rbid: number, rbrid: number }> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (building_report_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsBuildingsReports, building_report_ids.map(ids => ids.rbrid))
					.then(() => true)
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									building_report_ids.forEach(building_report_id => {
										if (building_report.id === building_report_id.rbrid) {
											filtered.push(building_report.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsReportsPush, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
			}
			if (building_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReports)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									building_ids.forEach(building_id => {
										if (building_report.building_id === building_id.rbid) {
											filtered.push(building_report);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsBuildingsReports, filtered.map(ids => ids.id))
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									building_ids.forEach(building_id => {
										if (building_report.building_id === building_id.rbid) {
											filtered.push(building_report);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsBuildingsReportsPush, filtered.map(ids => ids.entryId))
										.then(() => true)
										.catch(error => new Error(error));

								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReports)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];

								building_reports.forEach(building_report => {
									report_ids.forEach(report_id => {
										if (building_report.report_id === report_id.rid) {
											filtered.push(building_report.id);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsReports, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReportsPush)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									report_ids.forEach(report_id => {
										if (building_report.report_id === report_id.rid) {
											filtered.push(building_report.entryId);
										}
									});
								});

								if (filtered.length) {
									filtered = [...new Set(filtered)];
									return this.database.deleteBulk(this.tableReportsBuildingsReportsPush, filtered)
										.then(() => true)
										.catch(error => new Error(error));

								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportBuildingReportPhoto(report_ids: Array<{ rid: number }>, photo_ids: Array<{
		rid: number,
		rbid: number,
		rbrid: number,
		rbrpid: number
	}> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];

			if (photo_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReports)
					.then(building_reports => {
						if (typeof building_reports !== 'undefined') {
							if (building_reports.length) {
								let filtered = [];
								building_reports.forEach(building_report => {
									photo_ids.forEach(photo_id => {
										if (building_report.id === photo_id.rbrid && building_report.building_id === photo_id.rbid && typeof building_report.photos !== 'undefined') {
											if (typeof building_report.photos.inspector !== 'undefined') {
												building_report.photos.inspector.forEach((photo, index) => {
													if (parseInt(photo.id, 10) === photo_id.rbrpid) {
														building_report.photos.inspector.splice(index, 1);
													}
												});
											}
											if (typeof building_report.photos.by_client !== 'undefined') {
												building_report.photos.inspector.forEach((photo, index) => {
													if (parseInt(photo.id, 10) === photo_id.rbrpid) {
														building_report.photos.by_client.splice(index, 1);
													}
												});
											}
											filtered.push(building_report);
										}
									});
								});

								if (filtered.length) {
									return this.database.update(this.tableReportsBuildingsReports, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
					})
					.catch(error => new Error(error))
				);
			}
			if (report_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsBuildingsReportsPhotos)
					.then(report_building_photos => {
						if (typeof report_building_photos !== 'undefined') {
							if (report_building_photos.length) {
								let filtered = [];
								report_building_photos.forEach(report_building_photo => {
									report_ids.forEach(report_id => {
										if (report_building_photo.report_id === report_id.rid) {
											filtered.push(report_building_photo.id);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsBuildingsReportsPhotos, filtered)
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
					})
					.catch(error => new Error(error))
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteReportSendToClient(client_ids: Array<{ cid: number }> = null, report_ids: Array<{ rid: number }> = null): Promise<void> {
		return this.database.getAllFast(this.tableReportsSendToClient)
			.then(reports_send_to_client => {
				if (typeof reports_send_to_client !== 'undefined') {
					if (reports_send_to_client.length) {
						let filtered = [];
						if (client_ids !== null) {
							reports_send_to_client.forEach(report_send_to_client => {
								client_ids.forEach(client_id => {
									if (report_send_to_client.client_id === client_id.cid) {
										filtered.push(report_send_to_client.id);
									}
								});
							});
						}
						if (report_ids !== null) {
							reports_send_to_client.forEach(report_send_to_client => {
								report_ids.forEach(report_id => {
									if (report_send_to_client.report_id === report_id.rid) {
										filtered.push(report_send_to_client.id);
									}
								});
							});
						}

						if (filtered.length) {
							filtered = [...new Set(filtered)];
							return this.database.deleteBulk(this.tableReportsSendToClient, filtered)
								.then(() => true)
								.catch(error => new Error(error));
						}
					}
				}
				return true;
			})
			.catch(error => new Error(error));
	}

	deleteAction(action_ids: Array<{ waid: number }>): Promise<void> {
		return this.database.getAllFast(this.tableReportsActions)
			.then(actions => {
				if (actions !== 'undefined') {
					if (actions.length) {
						let filtered = [];
						actions.forEach(action => {
							action_ids.forEach(action_id => {
								if (action.id === action_id.waid) {
									filtered.push(action);
								}
							});
						});

						if (filtered.length) {
							return this.database.deleteBulk(this.tableReportsActions, filtered.map(ids => {
								return [ids.id, ids.category_id, ids.subcategory_id];
							}))
								.then(() => true)
								.catch(error => new Error(error));
						}
					}
				}
				return true;
			})
			.catch(error => new Error(error));
	}

	deleteCategory(category_ids: Array<{ wcid: number }>): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.database.deleteBulk(this.tableReportsCategories, category_ids.map(ids => ids.wcid))
				.then(() => true)
				.catch(error => new Error(error))
			);
			promises.push(this.database.getAllFast(this.tableReportsActions)
				.then(actions => {
					if (typeof actions !== 'undefined') {
						if (actions.length) {
							let filtered = [];
							actions.forEach(action => {
								category_ids.forEach(category_id => {
									if (action.category_id === category_id.wcid) {
										filtered.push(action);
									}
								});
							});

							if (filtered.length) {
								return this.database.deleteBulk(this.tableReportsActions, filtered.map(ids => {
									return [ids.id, ids.category_id, ids.subcategory_id];
								}))
									.then(() => true)
									.catch(error => new Error(error));
							}
						}
					}
					return true;
				})
				.catch(error => new Error(error))
			);
			promises.push(this.database.getAllFast(this.tableReportsTypes)
				.then(types => {
					if (typeof types !== 'undefined') {
						if (types.length) {
							let filtered = [];
							types.forEach(type => {
								category_ids.forEach(category_id => {
									if (type.category_id === category_id.wcid) {
										filtered.push(type);
									}
								});
							});

							if (filtered.length) {
								return this.database.deleteBulk(this.tableReportsTypes, filtered.map(ids => {
									return [ids.id, ids.category_id, ids.subcategory_id];
								}))
									.then(() => true)
									.catch(error => new Error(error));
							}
						}
					}
					return true;
				})
				.catch(error => new Error(error))
			);
			promises.push(this.deleteSubcategory(category_ids));

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteSubcategory(category_ids: Array<{ wcid: number }> = null, subcategory_ids: Array<{
		wcid: number,
		wsid: number
	}> = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			if (subcategory_ids !== null) {
				promises.push(this.database.deleteBulk(this.tableReportsSubcategories, subcategory_ids.map(ids => {
						return [ids.wsid, ids.wcid];
					}))
						.then(() => true)
						.catch(error => new Error(error))
				);

				promises.push(this.database.getAllFast(this.tableReportsActions)
					.then(actions => {
						if (typeof actions !== 'undefined') {
							if (actions.length) {
								let filtered = [];
								actions.forEach(action => {
									subcategory_ids.forEach(subcategory_id => {
										if (action.subcategory_id === subcategory_id.wsid) {
											filtered.push(action);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsActions, filtered.map(ids => {
										return [ids.id, ids.category_id, ids.subcategory_id];
									}))
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);

				promises.push(this.database.getAllFast(this.tableReportsTypes)
					.then(types => {
						if (typeof types !== 'undefined') {
							if (types.length) {
								let filtered = [];
								types.forEach(type => {
									subcategory_ids.forEach(subcategory_id => {
										if (type.subcategory_id === subcategory_id.wsid) {
											filtered.push(type);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsTypes, filtered.map(ids => {
										return [ids.id, ids.category_id, ids.subcategory_id];
									}))
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
			}
			if (category_ids !== null) {
				promises.push(this.database.getAllFast(this.tableReportsSubcategories)
					.then(subcategories => {
						if (typeof subcategories !== 'undefined') {
							if (subcategories.length) {
								let filtered = [];
								subcategories.forEach(subcategory => {
									category_ids.forEach(category_id => {
										if (subcategory.category_id === category_id.wcid) {
											filtered.push(subcategory);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsSubcategories, filtered.map(ids => {
										return [ids.id, ids.category_id];
									}))
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsActions)
					.then(actions => {
						if (typeof actions !== 'undefined') {
							if (actions.length) {
								let filtered = [];
								actions.forEach(action => {
									category_ids.forEach(category_id => {
										if (action.category_id === category_id.wcid) {
											filtered.push(action);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsActions, filtered.map(ids => {
										return [ids.id, ids.category_id, ids.subcategory_id];
									}))
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
				promises.push(this.database.getAllFast(this.tableReportsTypes)
					.then(types => {
						if (typeof types !== 'undefined') {
							if (types.length) {
								let filtered = [];
								types.forEach(type => {
									category_ids.forEach(category_id => {
										if (type.category_id === category_id.wcid) {
											filtered.push(type);
										}
									});
								});

								if (filtered.length) {
									return this.database.deleteBulk(this.tableReportsTypes, filtered.map(ids => {
										return [ids.id, ids.category_id, ids.subcategory_id];
									}))
										.then(() => true)
										.catch(error => new Error(error));
								}
							}
						}
						return true;
					})
					.catch(error => new Error(error))
				);
			}

			return Promise.all(promises).then(() => {
				resolve();
			});
		});
	}

	deleteType(type_ids: Array<{ wtid; number }>): Promise<void> {
		return this.database.getAllFast(this.tableReportsTypes)
			.then(types => {
				if (typeof types !== 'undefined') {
					if (types.length) {
						let filtered = [];
						types.forEach(type => {
							type_ids.forEach(type_id => {
								if (type.id === type_id.wtid) {
									filtered.push(type);
								}
							});
						});

						if (filtered.length) {
							return this.database.deleteBulk(this.tableReportsTypes, filtered.map(ids => {
								return [ids.id, ids.category_id, ids.subcategory_id];
							}))
								.then(() => true)
								.catch(error => new Error(error));
						}
					}
				}
				return true;
			})
			.catch(error => new Error(error));
	}

	deleteCategorySubcategoryAction(action_ids: Array<{ wcid: number, wsid: number, waid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableReportsActions, action_ids.map(ids => {
			return [ids.waid, ids.wcid, ids.wsid];
		}))
			.then(() => true)
			.catch(error => new Error(error));
	}

	deleteCategorySubcategoryType(type_ids: Array<{ wcid: number, wsid: number, wtid: number }>): Promise<void> {
		return this.database.deleteBulk(this.tableReportsTypes, type_ids.map(ids => {
			return [ids.wtid, ids.wcid, ids.wsid];
		}))
			.then(() => true)
			.catch(error => new Error(error));
	}

	redirectUser(dataParams) {
		let params = this.previousUrlService.routeData;
		setTimeout(() => {
			if (typeof params !== 'undefined' && typeof dataParams !== 'undefined') {
				if (params !== null && dataParams !== null) {
					if (typeof params['report_id'] !== 'undefined' && typeof dataParams['report_id'] !== 'undefined') {
						if (typeof params['building_id'] !== 'undefined' && typeof dataParams['building_id'] !== 'undefined') {
							if (typeof params['building_report_id'] !== 'undefined' && typeof dataParams['building_report_id'] !== 'undefined' && typeof dataParams['old_building_id'] !== 'undefined') {
								if (parseInt(dataParams['old_building_report_id'], 10) === parseInt(params['building_id'], 10)) {
									this.router.navigate(['/reports/' + dataParams['report_id'] + '/buildings/' + dataParams['building_id'] + '/reports/' + dataParams['building_report_id'] + '/edit']).then();
									return true;
								}
							}
							if (typeof dataParams['old_building_id'] !== 'undefined') {
								if (parseInt(dataParams['old_building_id'], 10) === parseInt(params['building_id'], 10)) {
									this.router.navigate(['/reports/' + dataParams['report_id'] + '/buildings/' + dataParams['building_id'] + '/reports']).then();
									return true;
								}
							}
						}
						if (typeof dataParams['report_id'] !== 'undefined' && typeof dataParams['old_report_id'] !== 'undefined') {
							if (parseInt(dataParams['old_report_id'], 10) === parseInt(params['report_id'], 10)) {
								this.router.navigate(['/reports/' + '/' + dataParams['report_id'] + '/view']).then();
								return true;
							}
						}
					}
				}
			}
		}, 1500);
	}

	/* KVVM Reports */

	pushToServer_KVVMReportsUpdates(): Promise<void> {
		return new Promise(resolve => {
			let promises = [],
				start = new Date().getTime();
			if (this.debug) {
				console.log((new Date().getTime() - start), 'pushToServer_KVVMReportsUpdate start ...');
			}

			return this.database.getAllFast(this.tableKvvmReportsBuildingsReportsSolved).then(reports => {
				if (typeof reports !== 'undefined') {
					if (reports.length) {
						reports.forEach(report => {
							promises.push(new Promise(resolve2 => {
								if (this.apiService.isOnline) {
									this.apiService.post('kvvm-reports/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id, report, false).then((data: ServerResponse) => {
										if (data.success === true) {
											let promises2 = [];
											promises2.push(this.database.update(this.tableKvvmReportsBuildingsReports, data.data)
												.then(() => true)
												.catch(error => new Error(error))
											);
											promises2.push(this.database.delete(this.tableKvvmReportsBuildingsReportsSolved, report.id)
												.then(() => true)
												.catch(error => new Error(error))
											);

											return Promise.all(promises2).then(() => {
												resolve2();
											});
										} else {
											resolve2();
										}
									}).catch(error => new Error(error));
								} else {
									resolve2();
								}
							}));
						});

						return Promise.all(promises).then(() => {
							if (this.debug) {
								console.log((new Date().getTime() - start), 'pushToServer_KVVMReportsUpdate done');
							}
							resolve();
						});
					} else {
						if (this.debug) {
							console.log((new Date().getTime() - start), 'pushToServer_KVVMReportsUpdate nothing to do');
						}
						resolve();
					}
				} else {
					if (this.debug) {
						console.log((new Date().getTime() - start), 'pushToServer_KVVMReportsUpdate nothing to do');
					}
					resolve();
				}
			}).catch(error => new Error(error));
		});
	}

	syncKVVMReports(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableKvvmReports).then(user_device_date => {
					this.apiService.get('kvvm-reports/sync/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('kvvm-reports/sync', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncKVVMReports start ...');
																}
																promises.push(this.database.updateBulk(this.tableKvvmReports, <Reports[]>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableKvvmReports,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncKVVMReports done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncKVVMReportsAttachments(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableKvvmReportsAttachments).then(user_device_date => {
					this.apiService.get('kvvm-reports/sync/attachments/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('kvvm-reports/sync/attachments', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncKVVMReportsAttachments start ...');
																}
																promises.push(this.database.updateBulk(this.tableKvvmReportsAttachments, <ReportsAttachments[]>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableKvvmReportsAttachments,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncKVVMReportsAttachments done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncKVVMReportsComments(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableKvvmReportsComments).then(user_device_date => {
					this.apiService.get('kvvm-reports/sync/comments/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('kvvm-reports/sync/comments', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncKVVMReportsComments start ...');
																}
																promises.push(this.database.updateBulk(this.tableKvvmReportsComments, <ReportsComments[]>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableKvvmReportsComments,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncKVVMReportsComments done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncKVVMReportsBuildings(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableKvvmReportsBuildings).then(user_device_date => {
					this.apiService.get('kvvm-reports/sync/buildings/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('kvvm-reports/sync/buildings', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncKVVMReportsBuildings start ...');
																}
																promises.push(this.database.updateBulk(this.tableKvvmReportsBuildings, <ReportsBuildings[]>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableKvvmReportsBuildings,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncKVVMReportsBuildings done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

	syncKVVMReportsBuildingsReports(): Promise<void> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				let promises = [];
				this.database.getByKey(this.tableDataSync, this.tableKvvmReportsBuildingsReports).then(user_device_date => {
					this.apiService.get('kvvm-reports/sync/buildings/reports/last-update-date', null, false).then((server_date: ServerResponse) => {
						if (typeof server_date !== 'undefined') {
							if (server_date.success === true) {
								if (typeof server_date.data !== 'undefined') {
									if (typeof server_date.data.updated !== 'undefined') {
										let update = false,
											last_update_date = null;

										if (typeof user_device_date !== 'undefined') {
											if (typeof user_device_date.updated !== 'undefined') {
												if (user_device_date.updated < server_date.data.updated) {
													last_update_date = user_device_date.updated;
													update = true;
												}
											} else {
												update = true;
											}
										} else {
											update = true;
										}

										if (update === true) {
											this.apiService.get('kvvm-reports/sync/buildings/reports', {
												start: 0,
												length: -1,
												last_update_date: last_update_date
											}, false).then((data: ServerResponse) => {
												if (typeof data !== 'undefined') {
													if (typeof data.success !== 'undefined') {
														if (data.success === true) {
															if (typeof data.data !== 'undefined') {
																let start = new Date().getTime();
																if (this.debug) {
																	console.log((new Date().getTime() - start), 'syncKVVMReportsBuildingsReports start ...');
																}
																promises.push(this.database.updateBulk(this.tableKvvmReportsBuildingsReports, <ReportsBuildingsReports[]>data.data)
																	.then(() => true)
																	.catch(error => new Error(error))
																);
																promises.push(this.database.update(this.tableDataSync, {
																		name: this.tableKvvmReportsBuildingsReports,
																		updated: server_date.data.updated
																	})
																		.then(() => true)
																		.catch(error => new Error(error))
																);

																return Promise.all(promises).then(() => {
																	if (this.debug) {
																		console.log((new Date().getTime() - start), 'syncKVVMReportsBuildingsReports done');
																	}
																	resolve();
																});
															} else {
																resolve();
															}
														} else {
															resolve();
														}
													} else {
														resolve();
													}
												} else {
													resolve();
												}
											}).catch(error => new Error(error));
										} else {
											resolve();
										}
									} else {
										resolve();
									}
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					}).catch(error => new Error(error));
				}).catch(error => new Error(error));
			} else {
				resolve();
			}
		});
	}

}
