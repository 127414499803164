import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../../route.animation';
import {GeneralService} from '../../../../../_services/general.service';
import {PreviousUrlService} from '../../../../../_services/previous-url.service';
import {ReportsBuildingsReports} from '../../../../../_interfaces/reports/reports-buildings-reports';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ServerResponse} from '../../../../../_interfaces/server.response';
import {ApiService} from '../../../../../_interceptors/api.service';
import {ReportsBuildings} from '../../../../../_interfaces/reports/reports-buildings';
import {PageTitleService} from '../../../../../_services/page-title.service';
import {environment} from '../../../../../../environments/environment';
import {KVVMReportsService} from '../../../../../_services/kvvm-reports.service';
import {ClientsBuildingMaps} from '../../../../../_interfaces/clients-building-maps';
import {ReportsBuildingsMapsMarker} from '../../../../../_interfaces/reports/reports-buildings-maps-marker';

@Component({
	selector: 'app-kvvm-reports-buildings-reports-list',
	templateUrl: './reports-buildings-reports-list.component.html',
	styleUrls: ['./reports-buildings-reports-list.component.scss'],
	animations: [routeAnimation]
})

export class KVVMReportsBuildingsReportsListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	private totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;
	private keepLoading: boolean = false;

	public report_id: number = null;
	public building_id: number = null;
	public buildingReports: ReportsBuildingsReports[] = [];

	public hasBuildingMap: boolean = false;
	public buildingMapUrl: string = null;
	public mapMarkers: ReportsBuildingsMapsMarker[] = [];

	private _activatedRouteSubscription: Subscription = null;

	@Input() smallWidget: boolean = false;

	constructor(private reportsService: KVVMReportsService,
				public apiService: ApiService,
				private activatedRoute: ActivatedRoute,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService,
				private generalService: GeneralService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && !this.buildingReports.length) {
				this.currentPage = 0;
				this.getReportsBuildingReports(false);
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);
			this.building_id = parseInt(params['building_id'], 10);

			this.reportsService.getBuilding(this.report_id, this.building_id).then((data: ReportsBuildings) => {
				if (typeof data !== 'undefined') {
					if (typeof data.building_map_id !== 'undefined') {
						if (data.building_map_id !== null) {
							this.reportsService.getBuildingMap(data.building_map_id).then((buildingMap: ClientsBuildingMaps) => {
								if (typeof buildingMap !== 'undefined') {
									this.hasBuildingMap = true;
									this.buildingMapUrl = buildingMap.map_url;
								}
							});
							this.reportsService.getBuildingMapMarkers(this.report_id, this.building_id).then((markers: ReportsBuildingsMapsMarker[]) => {
								this.mapMarkers = markers;
							});
						}
					}

					if (this.smallWidget === false) {
						this.pageTitleService.setTitle(data.name + ' | Gebouwen | ' + environment.webappName);
						this.pageTitleService.setPageTitle(data.name);
					}
				}
			});

			this.currentPage = 0;
			this.getReportsBuildingReports(false);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	getReportsBuildingReports(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.reportsService.getBuildingReports(this.report_id, this.building_id, {
				start: (this.currentPage - 1) * this.generalService.itemsPerPage,
				length: this.generalService.itemsPerPage,
				'columns[0][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}

					if (append_data === true) {
						this.buildingReports.push(...<ReportsBuildingsReports[]>data.data);
					} else {
						this.buildingReports = <ReportsBuildingsReports[]>data.data;
					}
				}
				this.isLoading = false;
			});
		}
	}

	search($event) {
		this.searchString = $event;
		this.currentPage = 0;
		this.getReportsBuildingReports(false);
	}

	showBuildingMap() {
		this.reportsService.showReportBuildingMap(this.buildingMapUrl, true, this.mapMarkers).then(() => {
		});
	}
}
