import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ReportsComments} from '../../../../_interfaces/reports/reports-comments';
import {ReportsService} from '../../../../_services/reports.service';
import {GeneralService} from '../../../../_services/general.service';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Params} from '@angular/router';
import {User} from '../../../../_interfaces/user';
import {ServerResponse} from '../../../../_interfaces/server.response';
import {ApiService} from '../../../../_interceptors/api.service';
import {PageTitleService} from '../../../../_services/page-title.service';
import {Reports} from '../../../../_interfaces/reports/reports';
import {environment} from '../../../../../environments/environment';
import {AuthenticationService} from '../../../../_services/authentication.service';

@Component({
	selector: 'app-reports-comments-list',
	templateUrl: './reports-comments-list.component.html',
	styleUrls: ['./reports-comments-list.component.scss'],
	animations: [routeAnimation]
})

export class ReportsCommentsListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;
	private keepLoading: boolean = false;

	public report_id: number = null;
	public reportComments: ReportsComments[] = [];

	private _activatedRouteSubscription: Subscription = null;
	private _authenticationServiceSubscription: Subscription = null;

	@Input() smallWidget: boolean = false;
	public user: User = null;

	constructor(private reportsService: ReportsService,
				private apiService: ApiService,
				private previousUrlService: PreviousUrlService,
				private activatedRoute: ActivatedRoute,
				private generalService: GeneralService,
				private authenticationService: AuthenticationService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && !this.reportComments.length) {
				this.currentPage = 0;
				this.getComments(false);
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);
			this._authenticationServiceSubscription = this.authenticationService.user.subscribe((user: User) => {
				if (typeof user !== 'undefined') {
					if (user !== null) {
						this.user = user;
					} else {
						this.user = undefined;
					}
				} else {
					this.user = undefined;
				}
			});

			if (this.smallWidget === false) {
				this.reportsService.getReport(this.report_id).then((report: Reports) => {
					if (typeof report !== 'undefined') {
						this.pageTitleService.setTitle(report.report_nr + ' | Rapportage opmerking | ' + environment.webappName);
						this.pageTitleService.setPageTitle(report.report_nr);
					}
				});
			}

			this.currentPage = 0;
			this.getComments(false);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
		if (this._authenticationServiceSubscription !== null) {
			this._authenticationServiceSubscription.unsubscribe();
		}
	}

	getComments(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.reportsService.getComments(this.report_id, {
				start: (this.currentPage - 1) * (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
				length: (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
				'columns[2][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}
					if (append_data === true) {
						this.reportComments.push(...<ReportsComments[]>data.data);
					} else {
						this.reportComments = <ReportsComments[]>data.data;
					}
				}
				this.isLoading = false;
			});
		}
	}

	search($event) {
		this.searchString = $event;
		this.currentPage = 0;
		this.getComments(false);
	}
}
