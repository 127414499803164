import {Injectable} from '@angular/core';
import {ApiService} from '../_interceptors/api.service';
import {ServerResponse} from '../_interfaces/server.response';
import {environment} from '../../environments/environment';
import {KnowledgeBase} from '../_interfaces/knowledge-base/knowledge-base';

@Injectable()

export class KnowledgeBaseService {

	public debug: boolean = environment.production;

	constructor(private apiService: ApiService) {
	}

	getKnowledgeBase(): Promise<KnowledgeBase[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('knowledge-base', {'language_id': 2, 'type': 'client'}).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<KnowledgeBase[]>data.data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getKnowledgeBaseArticles(knowledgeBaseUrl: string): Promise<KnowledgeBase> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('knowledge-base/' + knowledgeBaseUrl, {'language_id': 2, 'type': 'client'}).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<KnowledgeBase>data.data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getKnowledgeBaseArticle(knowledgeBaseUrl: string, knowledgeBaseArticleUrl: string): Promise<KnowledgeBase> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('knowledge-base/' + knowledgeBaseUrl + '/' + knowledgeBaseArticleUrl, {'language_id': 2, 'type': 'client'}).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<KnowledgeBase>data.data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}
}
