import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';
import {environment} from './environments/environment';
import {AppModule} from './app/app.module';
import 'zone.js';
import 'hammerjs';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {

	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.register('ngsw-worker.js').then(() => {});
	}
}).catch(error => console.log(error));
