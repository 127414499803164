import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../../route.animation';
import {FormBuilder} from '@angular/forms';
import {GeneralService} from '../../../../../_services/general.service';
import {FormsService} from '../../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../../../_services/previous-url.service';
import {ActivatedRoute, Params} from '@angular/router';
import {PageTitleService} from '../../../../../_services/page-title.service';
import {ReportsBuildingsReports} from '../../../../../_interfaces/reports/reports-buildings-reports';
import {ApiService} from '../../../../../_interceptors/api.service';
import {Reports} from '../../../../../_interfaces/reports/reports';
import {environment} from '../../../../../../environments/environment';
import {KVVMReportsService} from '../../../../../_services/kvvm-reports.service';
import {ReportsBuildings} from '../../../../../_interfaces/reports/reports-buildings';
import {ClientsBuildingMaps} from '../../../../../_interfaces/clients-building-maps';
import {ReportsBuildingsMapsMarker} from '../../../../../_interfaces/reports/reports-buildings-maps-marker';

@Component({
	selector: 'app-kvvm-reports-buildings-reports-edit',
	templateUrl: './reports-buildings-reports-view.component.html',
	styleUrls: ['./reports-buildings-reports-view.component.scss'],
	animations: [routeAnimation]
})

export class KVVMReportsBuildingsReportsViewComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public building_id: number = null;
	public building_report_id: number = null;
	public buildingReport: ReportsBuildingsReports = null;
	public dataLoaded: boolean = false;
	public report_type: string = null;

	public reportsBuildingsReportUpdateForm = this.formBuilder.group({
		location_nr: [{value: '', disabled: true}],
		client_fixed_status: [''],
		client_fixed_comment: ['']
	});

	public hasBuildingMap: boolean = false;
	public buildingMapUrl: string = null;
	public mapMarkers: ReportsBuildingsMapsMarker[] = [];

	private _activatedRouteSubscription: Subscription = null;

	constructor(private generalService: GeneralService,
				public apiService: ApiService,
				private formBuilder: FormBuilder,
				public reportsService: KVVMReportsService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private pageTitleService: PageTitleService,
				private previousUrlService: PreviousUrlService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online) {
				this.loadData();
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);
			this.building_id = parseInt(params['building_id'], 10);
			this.building_report_id = parseInt(params['building_report_id'], 10);

			this.reportsService.getReport(this.report_id).then((report: Reports) => {
				if (typeof report !== 'undefined') {
					this.report_type = report.type;
					this.reportsService.getBuilding(this.report_id, this.building_id).then((data: ReportsBuildings) => {
						if (typeof data !== 'undefined') {
							if (typeof data.building_map_id !== 'undefined') {
								if (data.building_map_id !== null) {
									this.reportsService.getBuildingMap(data.building_map_id).then((buildingMap: ClientsBuildingMaps) => {
										if (typeof buildingMap !== 'undefined') {
											this.hasBuildingMap = true;
											this.buildingMapUrl = buildingMap.map_url;
										}
									});
									this.reportsService.getBuildingMapMarkers(this.report_id, this.building_id, this.building_report_id).then((markers: ReportsBuildingsMapsMarker[]) => {
										this.mapMarkers = markers;
									});
								}
							}
						}
					});


					this.reportsService.getBuildingReport(this.report_id, this.building_id, this.building_report_id).then((buildingReport: ReportsBuildingsReports) => {
						if (typeof buildingReport !== 'undefined') {
							this.buildingReport = buildingReport;
							this.pageTitleService.setTitle(buildingReport.location_nr + ' | Schade toevoegen | ' + environment.webappName);
							this.pageTitleService.setPageTitle(buildingReport.location_nr);
						}
					});
				}
			});

			this.loadData();
		});

		this.previousUrlService.setPreviousUrlIfNull('/kvvm-reports/' + this.report_id + '/buildings/' + this.building_id + '/reports');
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	loadData() {
		this.reportsService.getBuildingReport(this.report_id, this.building_id, this.building_report_id).then((data: ReportsBuildingsReports) => {
			if (typeof data !== 'undefined') {
				this.buildingReport = data;
			}
		});
	}

	fixAngularScrewUpAndInsertActualValue2(control_name, event) {
		if (event.checked) {
			this.reportsBuildingsReportUpdateForm.controls[control_name].setValue(parseInt(event.source.value, 10));
		}
	}

	showBuildingMap() {
		this.reportsService.showReportBuildingMap(this.buildingMapUrl, true, this.mapMarkers).then(() => {
		});
	}
}
