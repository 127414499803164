import {Component, OnInit, ViewChild, OnDestroy, Input, ChangeDetectorRef, ApplicationRef} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {Router, NavigationEnd} from '@angular/router';
import {MediaReplayService} from '../sidenav/mediareplay/media-replay.service';
import {LoadingOverlayService} from '../../_services/loading-overlay.service';
import {MatDialog, MatDialogRef, MatSidenav, MatSnackBar} from '@angular/material';
import {SwUpdate} from '@angular/service-worker';
import {SimpleModalDialogComponent} from '../simple-modal/simple-modal.component';
import {MediaMatcher} from '@angular/cdk/layout';
import {IosInstallPromptComponent} from '../ios-install-prompt/ios-install-prompt.component';
import {LocalStorageService} from '../../_services/local-storage.service';
import * as moment from 'moment';
import {ApiService} from '../../_interceptors/api.service';
import {PreviousUrlService} from '../../_services/previous-url.service';
import {concat, interval} from 'rxjs';
import {first} from 'rxjs/operators';
import {GeneralService} from '../../_services/general.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {User} from '../../_interfaces/user';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit, OnDestroy {
	@ViewChild('sidenav', {static: false}) sidenav: MatSidenav;

	@Input() isLoading: boolean = false;
	public progress_type: any = 'indeterminate';
	public progress_percentage: number = 0;

	public loadingError: boolean = false;
	public offlineError: boolean = false;
	public tfa_not_enabled: boolean = null;
	public tfa_required: boolean = null;

	private _routerEventsSubscription: Subscription = null;
	private _loadingOverlaySubscription: Subscription = null;
	private _loadingOverlayTypeSubscription: Subscription = null;
	private _loadingOverlayProgressSubscription: Subscription = null;
	private _offlineErrorSubscription: Subscription = null;
	private _navigatedSubscription: Subscription = null;
	private _loadingErrorSubscription: Subscription = null;
	private _userSubscription: Subscription = null;

	constructor(private router: Router,
				private authenticationService: AuthenticationService,
				private applicationRef: ApplicationRef,
				private swUpdate: SwUpdate,
				private dialogService: MatDialog,
				private mediaReplayService: MediaReplayService,
				private loadingOverlayService: LoadingOverlayService,
				private changeDetectorRef: ChangeDetectorRef,
				private localStorageService: LocalStorageService,
				private media: MediaMatcher,
				private apiService: ApiService,
				private previousUrlService: PreviousUrlService,
				private generalService: GeneralService,
				private snackBar: MatSnackBar) {
	}

	ngOnInit() {
		this.generalService.getSettings().then((settings: any) => {
			this._userSubscription = this.authenticationService.user.subscribe((user: User) => {
				if (typeof user !== 'undefined' && user !== null) {
					let tfa_enabled_status = settings.filter(obj => obj.name === 'tfa_enabled'),
						tfa_enabled = (tfa_enabled_status.length ? tfa_enabled_status[0].value : false),
						tfa_required_status = (
							[1, 2, 5].indexOf(user.id) !== -1 ? settings.filter(obj => obj.name === 'tfa_required_mobile_app') :
								(user.account_type === 'ikea' && [3, 4, 6, 7].indexOf(user.type_id) !== -1 ? settings.filter(obj => obj.name === 'tfa_required_ikea_mobile_app') :
										(user.account_type === 'client' && [3, 4, 6, 7].indexOf(user.type_id) !== -1 ? settings.filter(obj => obj.name === 'tfa_required_client_mobile_app') :
												false
										)
								)
						),
						tfa_required = (user.account_type === 'kvvm' && [1, 2, 5].indexOf(user.type_id) !== -1 && (tfa_required_status.length ? tfa_required_status[0].value : false) ||
							user.account_type === 'ikea' && [3, 4, 6, 7].indexOf(user.type_id) !== -1 && (tfa_required_status.length ? tfa_required_status[0].value : false) ||
							user.account_type === 'client' && [3, 4, 6, 7].indexOf(user.type_id) !== -1 && (tfa_required_status.length ? tfa_required_status[0].value : false)
						);

					if (typeof tfa_enabled !== 'undefined' && typeof user.tfa_enabled !== 'undefined') {
						if (tfa_enabled === true && tfa_required === true && user.tfa_enabled === false) {
							this.tfa_required = true;
						} else if (tfa_enabled === true && user.tfa_enabled === false) {
							this.tfa_not_enabled = true;
						} else {
							this.tfa_required = false;
							this.tfa_not_enabled = false;
						}
					}
				}
			});
		});

		this._routerEventsSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.sidenav.close().then(() => {
				});
				this.loadingOverlayService.hideError();
				this.loadingOverlayService.hide();
			}
		});

		this._loadingOverlaySubscription = this.loadingOverlayService.overlay.subscribe(event => this.isLoading = event);

		this._loadingOverlayTypeSubscription = this.loadingOverlayService.progress_type.subscribe(event => this.progress_type = (event === true ? 'determinate' : 'indeterminate'));

		this._loadingOverlayProgressSubscription = this.loadingOverlayService.percentage.subscribe(event => this.progress_percentage = event);

		this._loadingErrorSubscription = this.loadingOverlayService.error.subscribe(event => this.loadingError = event);

		this._offlineErrorSubscription = this.apiService.offlineError.subscribe(event => this.offlineError = event);

		this._navigatedSubscription = this.previousUrlService.navigated.subscribe(() => window.scrollTo(0, 0));

		const appIsStable$ = this.applicationRef.isStable.pipe(first(isStable => isStable === true));
		const everyFiveMinutes$ = interval(5 * 60 * 1000);
		const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everyFiveMinutes$);

		everySixHoursOnceAppIsStable$.subscribe(() => this.swUpdate.checkForUpdate());
		if (this.swUpdate.isEnabled) {
			this.swUpdate.available.subscribe(() => {
				console.log('ServiceWorker update available: yes');
				let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
				dialogRef = this.dialogService.open(SimpleModalDialogComponent);
				dialogRef.componentInstance.text = 'Er is een nieuwe versie van de app beschikbaar, het is aanbevolen om deze zo snel mogelijk te opwaarderen.<br><br>Update nu uitvoeren?';
				dialogRef.beforeClosed().subscribe(response => {
					if (response === true) {
						this.swUpdate.activateUpdate().then(() => {
							window.location.reload();
						});
					}
				});
			});
		}

		setTimeout(() => {
			try {
				window.dispatchEvent(new Event('resize'));
			} catch (e) {
				const resizeEvent = window.document.createEvent('UIEvents');
				resizeEvent.initUIEvent('resize', true, false, window, 0);
				window.dispatchEvent(resizeEvent);
			}
		}, 2000);

		// detects if app is run on iOS device
		const isIos = () => {
			const userAgent = window.navigator.userAgent.toLowerCase();
			return /iphone|ipad|ipod/.test(userAgent);
		};

		// detects if device is in standalone mode
		const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);

		// check when the last time the prompt was shown
		const showIosPrompt = () => {
			const storedDate = this.localStorageService.get('kvvm-client-ios-install-prompt'),
				today = moment();

			if (storedDate !== null) {
				const lastPromptDate = moment(storedDate);
				if (today.diff(lastPromptDate, 'days') >= 5) {
					return true;
				}
			} else {
				return true;
			}
			return false;
		};

		// checks if it should display install popup notification:
		if (isIos() && !isInStandaloneMode() && showIosPrompt()) {
			this.snackBar.openFromComponent(IosInstallPromptComponent, {
				duration: 15000,
				horizontalPosition: 'start',
				panelClass: ['mat-elevation-z3']
			});
		}
	}

	ngOnDestroy() {
		if (this._routerEventsSubscription !== null) {
			this._routerEventsSubscription.unsubscribe();
		}
		if (this._loadingOverlaySubscription !== null) {
			this._loadingOverlaySubscription.unsubscribe();
		}
		if (this._loadingOverlayTypeSubscription !== null) {
			this._loadingOverlayTypeSubscription.unsubscribe();
		}
		if (this._loadingOverlayProgressSubscription !== null) {
			this._loadingOverlayProgressSubscription.unsubscribe();
		}
		if (this._offlineErrorSubscription !== null) {
			this._offlineErrorSubscription.unsubscribe();
		}
		if (this._navigatedSubscription !== null) {
			this._navigatedSubscription.unsubscribe();
		}
		if (this._loadingErrorSubscription !== null) {
			this._loadingErrorSubscription.unsubscribe();
		}
		if (this._userSubscription !== null) {
			this._userSubscription.unsubscribe();
		}
	}
}
