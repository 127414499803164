import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ReportsService} from '../../../_services/reports.service';

@Component({
	selector: 'app-reports-create',
	templateUrl: './reports-create.component.html',
	styleUrls: ['./reports-create.component.scss'],
	animations: [routeAnimation]
})

export class ReportsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public reportsCreateForm = this.formBuilder.group({
		report_nr: ['', Validators.required],
		client_description: ['']
	});

	private _errorsSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				public formsService: FormsService,
				private previousUrlService: PreviousUrlService,
				private formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.formsService.form = this.reportsCreateForm;

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this.previousUrlService.setPreviousUrlIfNull('/reports');
	}

	ngOnDestroy(): void {
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}
}
