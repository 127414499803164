import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AdminComponent} from './core/admin/admin.component';
import {LoginComponent} from './core/login/login/login.component';
import {ForgotPasswordComponent} from './core/login/forgot-password/forgot-password.component';
import {AuthGuard} from './_services/authguard.service';
import {ChangePasswordComponent} from './core/security/change-password/change-password.component';

import {environment} from '../environments/environment';
import {PageNotFoundComponent} from './core/page-not-found/page-not-found.component';
import {PageForbiddenComponent} from './core/page-forbidden/page-forbidden.component';
import {DashboardComponent} from './webapp/dashboard/dashboard.component';
import {ReportsRouterComponent} from './webapp/reports/reports-router/reports-router.component';
import {ReportsListComponent} from './webapp/reports/reports-list/reports-list.component';
import {ReportsCreateComponent} from './webapp/reports/reports-create/reports-create.component';
import {ReportsEditComponent} from './webapp/reports/reports-edit/reports-edit.component';
import {ReportsAttachmentsCreateComponent} from './webapp/reports/reports-attachments/reports-attachments-create/reports-attachments-create.component';
import {ReportsAttachmentsListComponent} from './webapp/reports/reports-attachments/reports-attachments-list/reports-attachments-list.component';
import {ReportsBuildingsListComponent} from './webapp/reports/reports-buildings/reports-buildings-list/reports-buildings-list.component';
import {ReportsBuildingsCreateComponent} from './webapp/reports/reports-buildings/reports-buildings-create/reports-buildings-create.component';
import {ReportsBuildingsEditComponent} from './webapp/reports/reports-buildings/reports-buildings-edit/reports-buildings-edit.component';
import {ReportsBuildingsReportsListComponent} from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-buildings-reports-list.component';
import {ReportsBuildingsReportsCreateComponent} from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-create/reports-buildings-reports-create.component';
import {ReportsBuildingsReportsEditComponent} from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-edit/reports-buildings-reports-edit.component';
import {ReportsCommentsCreateComponent} from './webapp/reports/reports-comments/reports-comments-create/reports-comments-create.component';
import {ReportsCommentsEditComponent} from './webapp/reports/reports-comments/reports-comments-edit/reports-comments-edit.component';
import {ReportsCommentsListComponent} from './webapp/reports/reports-comments/reports-comments-list/reports-comments-list.component';
import {ReportsViewComponent} from './webapp/reports/reports-view/reports-view.component';
import {ReportsSendToEmailComponent} from './webapp/reports/reports-send-to-client/reports-send-to-email.component';
import {KnowledgeBaseComponent} from './webapp/knowledge-base/knowledge-base/knowledge-base.component';
import {KVVMReportsRouterComponent} from './webapp/kvvm-reports/reports-router/reports-router.component';
import {KVVMReportsListComponent} from './webapp/kvvm-reports/reports-list/reports-list.component';
import {KVVMReportsViewComponent} from './webapp/kvvm-reports/reports-view/reports-view.component';
import {KVVMReportsAttachmentsListComponent} from './webapp/kvvm-reports/reports-attachments-list/reports-attachments-list.component';
import {KVVMReportsCommentsListComponent} from './webapp/kvvm-reports/reports-comments-list/reports-comments-list.component';
import {KVVMReportsBuildingsListComponent} from './webapp/kvvm-reports/reports-buildings/reports-buildings-list/reports-buildings-list.component';
import {KVVMReportsBuildingsReportsListComponent} from './webapp/kvvm-reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-buildings-reports-list.component';
import {KVVMReportsBuildingsReportsViewComponent} from './webapp/kvvm-reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-view/reports-buildings-reports-view.component';
import {KnowledgeBaseArticlesComponent} from './webapp/knowledge-base/knowledge-base-articles/knowledge-base-articles.component';
import {KnowledgeBaseRouterComponent} from './webapp/knowledge-base/knowledge-base-router/knowledge-base-router.component';
import {KnowledgeBaseArticleComponent} from './webapp/knowledge-base/knowledge-base-article/knowledge-base-article.component';
import {LoginTwoFactorAuthenticationComponent} from './core/login/login-two-factor-authentication/login-two-factor-authentication.component';
import {LoginWithRecoveryCodeComponent} from './core/login/login-with-recovery-code/login-with-recovery-code.component';
import {RecoverLostPasswordComponent} from './core/login/recover-lost-password/recover-lost-password.component';
import {MyAccountRouterComponent} from './core/security/my-account-router/my-account-router.component';
import {MyAccountComponent} from './core/security/my-account/my-account.component';
import {TwoFactorAuthenticationComponent} from './core/security/two-factor-authentication/two-factor-authentication.component';
import {TwoFactorAuthenticationEnableComponent} from './core/security/two-factor-authentication-enable/two-factor-authentication-enable.component';
import {TwoFactorAuthenticationDisableComponent} from './core/security/two-factor-authentication-disable/two-factor-authentication-disable.component';
import {TwoFactorAuthenticationRegenerateRecoveryCodesComponent} from './core/security/two-factor-authentication-regenerate-recovery-codes/two-factor-authentication-regenerate-recovery-codes.component';

const routes: Routes = [
		{
			path: 'login',
			component: LoginComponent,
			data: {
				title: 'Login | ' + environment.webappName
			}
		},
		{
			path: 'two-factor-authentication',
			component: LoginTwoFactorAuthenticationComponent,
			data: {
				title: 'Twee-factor-authenticatie login | ' + environment.webappName
			}
		},
		{
			path: 'login-with-recovery-code',
			component: LoginWithRecoveryCodeComponent,
			data: {
				title: 'Twee-factor herstelcode login | ' + environment.webappName
			}
		},
		{
			path: 'wachtwoord-vergeten',
			component: ForgotPasswordComponent,
			data: {
				title: 'Watchword vergeten | ' + environment.webappName
			}
		},
		{
			path: 'recover-lost-password/:recovery_token',
			component: RecoverLostPasswordComponent,
			data: {
				title: 'Watchword vergeten | ' + environment.webappName
			}
		},
		{
			path: 'public/knowledge-base',
			component: KnowledgeBaseRouterComponent,
			data: {
				title: 'Knowledge base | ' + environment.webappName
			},
			children: [
				{
					path: '',
					component: KnowledgeBaseComponent,
					data: {
						title: 'Knowledge base | ' + environment.webappName
					}
				},
				{
					path: ':group_url',
					component: KnowledgeBaseArticlesComponent,
					data: {
						title: 'Knowledge base | ' + environment.webappName
					}
				},
				{
					path: ':group_url/:article_url',
					component: KnowledgeBaseArticleComponent,
					data: {
						title: 'Knowledge base | ' + environment.webappName
					}
				}
			]
		},
		{
			path: '',
			component: AdminComponent,
			canActivate: [AuthGuard],
			canActivateChild: [AuthGuard],
			data: {
				title: environment.webappName
			},
			children: [
				{
					path: '',

					component: DashboardComponent,
					data: {
						title: 'Overzicht | ' + environment.webappName
					}
				},
				{
					path: 'my-account',
					component: MyAccountRouterComponent,
					data: {
						title: 'Mijn profiel aanpassen | ' + environment.webappName
					},
					children: [
						{
							path: '',
							component: MyAccountComponent,
							data: {
								title: 'Mijn profiel aanpassen | ' + environment.webappName
							}
						},
						{
							path: 'two-factor-authentication',
							component: TwoFactorAuthenticationComponent,
							data: {
								title: 'Twee-factor-authenticatie | ' + environment.webappName
							}
						},
						{
							path: 'two-factor-authentication/enable',
							component: TwoFactorAuthenticationEnableComponent,
							data: {
								title: 'Twee-factor-authenticatie inschakelen | ' + environment.webappName
							}
						},
						{
							path: 'two-factor-authentication/disable',
							component: TwoFactorAuthenticationDisableComponent,
							data: {
								title: 'Twee-factor-authenticatie uitschakelen | ' + environment.webappName
							}
						},
						{
							path: 'two-factor-authentication/regenerate-recovery-codes',
							component: TwoFactorAuthenticationRegenerateRecoveryCodesComponent,
							data: {
								title: 'Herstelcodes opnieuw genereren | ' + environment.webappName
							}
						}
					]
				},
				{
					path: 'wachtwoord-aanpassen',
					component: ChangePasswordComponent,
					data: {
						title: 'Wachtwoord aanpassen | ' + environment.webappName
					}
				},
				{
					path: 'reports',
					component: ReportsRouterComponent,
					children: [
						{
							path: '',
							component: ReportsListComponent,
							data: {
								title: 'Rapportages | ' + environment.webappName
							}
						},
						{
							path: 'create',
							component: ReportsCreateComponent,
							data: {
								title: 'Nieuwe rapportage toevoegen | ' + environment.webappName
							}
						},
						{
							path: ':report_id/edit',
							component: ReportsEditComponent,
							data: {
								title: 'Aanpassen Rapportage | ' + environment.webappName
							}
						},
						{
							path: ':report_id/view',
							component: ReportsViewComponent,
							data: {
								title: 'Bekijk rapportage | ' + environment.webappName
							}
						},
						{
							path: ':report_id/attachments',
							component: ReportsAttachmentsListComponent,
							data: {
								title: 'Rapportage bijlagen | ' + environment.webappName
							}
						},
						{
							path: ':report_id/attachments/create',
							component: ReportsAttachmentsCreateComponent,
							data: {
								title: 'Rapportage bijlage toevoegen | ' + environment.webappName
							}
						},
						{
							path: ':report_id/comments',
							component: ReportsCommentsListComponent,
							data: {
								title: 'Rapportage opmerking | ' + environment.webappName
							}
						},
						{
							path: ':report_id/comments/create',
							component: ReportsCommentsCreateComponent,
							data: {
								title: 'Rapportage opmerking toevoegen | ' + environment.webappName
							}
						},
						{
							path: ':report_id/comments/:comment_id/edit',
							component: ReportsCommentsEditComponent,
							data: {
								title: 'Aanpassen rapportage opmerking | ' + environment.webappName
							}
						},
						{
							path: ':report_id/buildings',
							component: ReportsBuildingsListComponent,
							data: {
								title: 'Rapportage gebouwen | ' + environment.webappName
							}
						},
						{
							path: ':report_id/buildings/create',
							component: ReportsBuildingsCreateComponent,
							data: {
								title: 'Nieuw rapportage gebouw | ' + environment.webappName
							}
						},
						{
							path: ':report_id/buildings/:building_id/edit',
							component: ReportsBuildingsEditComponent,
							data: {
								title: 'Aanpassen rapportage gebouw | ' + environment.webappName
							}
						},
						{
							path: ':report_id/buildings/:building_id/reports',
							component: ReportsBuildingsReportsListComponent,
							data: {
								title: 'Gebouw rapportages | ' + environment.webappName
							}
						},
						{
							path: ':report_id/buildings/:building_id/reports/create',
							component: ReportsBuildingsReportsCreateComponent,
							data: {
								title: 'Nieuw gebouw rapportage | ' + environment.webappName
							}
						},
						{
							path: ':report_id/buildings/:building_id/reports/:building_report_id/edit',
							component: ReportsBuildingsReportsEditComponent,
							data: {
								title: 'Aanpassen gebouw rapportage | ' + environment.webappName
							}
						},
						{
							path: ':report_id/send-to-email',
							component: ReportsSendToEmailComponent,
							data: {
								title: 'Verstuur rapportage naar email | ' + environment.webappName
							}
						}
					]
				},
				{
					path: 'kvvm-reports',
					component: KVVMReportsRouterComponent,
					children: [
						{
							path: '',
							component: KVVMReportsListComponent,
							data: {
								title: 'Rapportages | ' + environment.webappName
							}
						},
						{
							path: ':report_id/view',
							component: KVVMReportsViewComponent,
							data: {
								title: 'Bekijk rapportage | ' + environment.webappName
							}
						},
						{
							path: ':report_id/attachments',
							component: KVVMReportsAttachmentsListComponent,
							data: {
								title: 'Rapportage bijlagen | ' + environment.webappName
							}
						},
						{
							path: ':report_id/comments',
							component: KVVMReportsCommentsListComponent,
							data: {
								title: 'Rapportage opmerking | ' + environment.webappName
							}
						},
						{
							path: ':report_id/buildings',
							component: KVVMReportsBuildingsListComponent,
							data: {
								title: 'Rapportage gebouwen | ' + environment.webappName
							}
						},
						{
							path: ':report_id/buildings/:building_id/reports',
							component: KVVMReportsBuildingsReportsListComponent,
							data: {
								title: 'Gebouw rapportages | ' + environment.webappName
							}
						},
						{
							path: ':report_id/buildings/:building_id/reports/:building_report_id/view',
							component: KVVMReportsBuildingsReportsViewComponent,
							data: {
								title: 'Bekijk gebouw rapportage | ' + environment.webappName
							}
						}
					]
				},
				{
					path: 'knowledge-base',
					component: KnowledgeBaseRouterComponent,
					data: {
						title: 'Knowledge base | ' + environment.webappName
					},
					children: [
						{
							path: '',
							component: KnowledgeBaseComponent,
							data: {
								title: 'Knowledge base | ' + environment.webappName
							}
						},
						{
							path: ':group_url',
							component: KnowledgeBaseArticlesComponent,
							data: {
								title: 'Knowledge base | ' + environment.webappName
							}
						},
						{
							path: ':group_url/:article_url',
							component: KnowledgeBaseArticleComponent,
							data: {
								title: 'Knowledge base | ' + environment.webappName
							}
						}
					]
				},
				{
					path: '403',
					component: PageForbiddenComponent,
					data: {
						title: 'Forbidden | ' + environment.webappName
					}
				},
				{
					path: '**',
					component: PageNotFoundComponent,
					data: {
						title: 'Not found | ' + environment.webappName
					}
				}
			]
		}
	]
;

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: []
})
export class RoutingModule {
}
