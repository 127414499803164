import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Params} from '@angular/router';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ReportsService} from '../../../_services/reports.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {ApiService} from '../../../_interceptors/api.service';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';

@Component({
	selector: 'app-reports-edit',
	templateUrl: './reports-edit.component.html',
	styleUrls: ['./reports-edit.component.scss'],
	animations: [routeAnimation]
})

export class ReportsEditComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	public reportsEditForm = this.formBuilder.group({
		report_nr: ['', Validators.required],
		client_description: ['']
	});

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;

	constructor(private activatedRoute: ActivatedRoute,
				private apiService: ApiService,
				public reportsService: ReportsService,
				public formsService: FormsService,
				private previousUrlService: PreviousUrlService,
				private formBuilder: FormBuilder,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.formsService.form = this.reportsEditForm;

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);

			this.loadData();

			this.previousUrlService.setPreviousUrlIfNull('/reports');
		});

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	loadData() {
		this.reportsService.getReport(this.report_id).then((data: Reports) => {
			if (typeof data !== 'undefined') {
				this.formsService.populateForm(data);

				this.pageTitleService.setTitle(data.report_nr + ' | Aanpassen rapportage | ' + environment.webappName);
				this.pageTitleService.setPageTitle(data.report_nr);

				this.dataLoaded = true;
			} else {
				this.missingData.push('Rapportage');
			}
		});
	}
}
