import {Component, OnInit, ViewEncapsulation, OnDestroy} from '@angular/core';
import {SidenavItem} from './sidenav-item/sidenav-item.model';
import {SidenavService} from './sidenav.service';
import * as _ from 'lodash';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {version} from '../../../environments/version';

@Component({
	selector: 'app-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class SidenavComponent implements OnInit, OnDestroy {

	public version = version;

	items: SidenavItem[];
	bottomItems: SidenavItem[];

	private _itemsSubscription: Subscription = null;
	private _bottomItemsSubscription: Subscription = null;
	private _routerEventsSubscription: Subscription = null;

	constructor(private sidenavService: SidenavService,
				private router: Router) {
	}

	ngOnInit(): void {
		this._itemsSubscription = this.sidenavService.items$
			.subscribe((items: SidenavItem[]) => {
				this.items = this.sortRecursive(items, 'position');
			});

		this._bottomItemsSubscription = this.sidenavService.bottomItems$
			.subscribe((items: SidenavItem[]) => {
				this.bottomItems = this.sortRecursive(items, 'position');
			});

		this._routerEventsSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.sidenavService.nextCurrentlyOpenByRoute(event.url);
				setTimeout(() => {
					try {
						window.dispatchEvent(new Event('resize'));
					} catch (e) {
						const resizeEvent = window.document.createEvent('UIEvents');
						resizeEvent.initUIEvent('resize', true, false, window, 0);
						window.dispatchEvent(resizeEvent);
					}
				}, 400);
			}
		});
	}

	sortRecursive(array: SidenavItem[], propertyName: string) {
		array.forEach(item => {
			const keys = _.keys(item);
			keys.forEach(key => {
				if (_.isArray(item[key])) {
					item[key] = this.sortRecursive(item[key], propertyName);
				}
			});
		});

		return _.sortBy(array, propertyName);
	}

	ngOnDestroy(): void {
		if (this._itemsSubscription !== null) {
			this._itemsSubscription.unsubscribe();
		}
		if (this._bottomItemsSubscription !== null) {
			this._bottomItemsSubscription.unsubscribe();
		}
		if (this._routerEventsSubscription !== null) {
			this._routerEventsSubscription.unsubscribe();
		}
	}
}
